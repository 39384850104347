.listContainer {
	flex: 1 1 0;
	border-bottom: 1px solid #ced4da;
	overflow: hidden;
	position: relative;

	> ul {
		list-style: none;
		margin: 0;
		padding: 0;
		overflow-y: auto;
		max-height: calc(100% - 48px);
	}
}
.listContainer_mobile {
	> ul {
		max-height: 100%;
	}
}
.placeholder {
	padding: 50px;
	text-align: center;
	width: 100%;
}

.listHeader {
	display: flex;
	border-bottom: 1px solid #ced4da;
	width: 100%;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: -0.03em;

	> div:first-of-type {
		max-width: 48px;
		min-width: 48px;
		width: 48px;
		height: 48px;
		display: grid;
		place-items: center;
		border-right: 1px solid #ced4da;

		> div > input {
			cursor: pointer;
		}
	}

	> section:first-of-type {
		flex: 1 1 0;
		display: flex;
		align-items: center;
		padding-left: 16px;
		cursor: pointer;
		gap: 4px;
	}

	> div:last-of-type {
		max-width: 120px;
		width: 120px;
		height: 48px;
		display: flex;
		align-items: center;
		padding-left: 16px;
		border-left: 1px solid #ced4da;
		cursor: pointer;
		gap: 4px;
	}
}

.listItem {
	display: flex;
	border-bottom: 1px solid #ced4da;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}
}

.itemCheckBox {
	max-width: 48px;
	min-width: 48px;
	border-right: 1px solid #ced4da;
	display: flex;
	justify-content: center;
	padding-top: 16px;
	font-size: 12px;
	cursor: pointer;

	input {
		cursor: pointer;
	}
}

.listInfo {
	padding: 16px;
	width: 100%;

	> section {
		display: flex;
		gap: 16px;

		> div > span {
			font-size: 12px;
			white-space: pre-wrap;
		}
	}
}

.selectedOperations {
	width: 95%;
	max-width: 564px;
	margin: 0 auto;
	background-color: #212529;
	border-radius: 8px;
	min-height: 56px;
	max-height: 56px;
	overflow: hidden;
	color: #ffffff;
	padding: 10px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bannerFrame {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	position: absolute;
	width: 408px;
	height: 100%;
	left: 0px;
	top: 0px;
	overflow-y: auto;
	overflow-x: hidden;
}

.contentMain {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 24px 0 24px;
	isolation: isolate;
	width: 408px;
	background: #ffffff;
	flex: none;
	flex-grow: 0;
	cursor: pointer;
	// white-space: normal;
	height: auto;
}

// .withLine:after {
// 	content: '';
// 	display: block;
// 	width: 100%;
// 	height: 1px;
// 	background-color: gray;
// 	margin-top: 10px;
// }

.line {
	width: 100%;
	height: 1px;
	background-color: #ced4da;
}

.contentMain:hover {
	background: #f5f5f5;
}

.iconFrame {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 16px;
	margin-bottom: 8px;

	width: 360px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;
	z-index: 0;
}

.bannerType {
	width: 40px;
	height: 40px;
	background: #007bff;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.billboard {
	position: absolute;
	left: 20%;
	right: 20%;
	top: 20%;
	bottom: 20%;
}

.uniQon {
	position: absolute;
	border-radius: 50%;
	display: grid;
	place-items: center;
}

.grayBackground {
	background: #f5f5f5;
}

.bannerState {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding: 0px;

	width: auto;
	height: 18px;

	flex: none;
	flex-grow: 0;
}

.bannerBadge {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3px 4px;
	width: auto;
	height: 18px;
	background: #007bff;
	border-radius: 4px;
	flex: none;
	flex-grow: 0;

	& > span {
		width: auto;
		height: 18px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		text-align: right;
		letter-spacing: -0.03em;
		color: #ffffff;
		flex: none;
		flex-grow: 0;
		white-space: nowrap;
	}
}

.grayBadge {
	background: gray;
}

.bannerTitle {
	display: -webkit-box;
	width: 360px;
	max-height: 135px;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;

	/* Heading/H6 */

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	margin-bottom: 16px;

	letter-spacing: -0.03em;

	color: #212529;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
	z-index: 1;
}

.separatorLine {
	position: relative;
	width: 100%;
	height: 1px;
	background-color: black;
}

//booking component sidebar /
.componentSideBar {
	position: fixed;
	width: 100%;
	left: 0px;
	top: 0px;
	bottom: 0px;
	background: #ffffff;
	overflow-y: auto;
	overflow-x: hidden;
}

.componentOneFrame {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	position: absolute;
	width: 408px;
}

.componentOneFrameOne {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 16px;
	width: 408px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
	border-bottom: 1px solid #ced4da;
}

.componentOneFrameOneIcons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 16px;
	width: 360px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.componentOneFrameOneIconsPin {
	width: 56px;
	// background: #007BFF;
	border: 2px solid #ffffff;
	border-radius: 40px;
	flex: none;
	flex-grow: 0;

	& > span {
		position: absolute;
		left: 20%;
		right: 20%;
		top: 20%;
		bottom: 20%;
	}
}

.whitebox {
	// position: absolute;
	width: 408px;
	padding: 16px 24px;
	background: #ffffff;
	border-bottom: 1px solid #ced4da;
}

.backbutton {
	background-color: transparent;
	color: gray;
	border: none;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
}

.arrow-icon {
	margin-right: 5px;
}

.backButtonSidebar {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;

	position: absolute;
	width: 70px;
	height: 24px;
	left: 24px;

	& > button {
		width: 46px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		color: #6c757d;
		flex: none;
		flex-grow: 0;
	}
}

.componentOneFrameOneText {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;
	isolation: isolate;
	width: 360px;
	flex: none;
	flex-grow: 0;

	& > h1 {
		width: 360px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		display: flex;
		align-items: center;
		letter-spacing: -0.04em;
		color: #212529;
		flex: none;
		flex-grow: 0;
		z-index: 0;
		margin-bottom: 0;
	}

	& > span {
		position: absolute;
		height: 0px;
		left: -24px;
		right: -24px;
		border: 1px solid #ced4da;
		flex: none;
		flex-grow: 0;
		z-index: 1;
		margin-top: 100px;
		margin-right: 23px;
	}
}

.componentOneFrameTwo {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	align-self: stretch;
	flex-grow: 0;
	border-bottom: 1px solid #ced4da;
}

.componentOneFrameTwoBook {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 24px;
	gap: 10px;
	isolation: isolate;
	width: 204px;
	height: 48px;
	flex: none;
	flex-grow: 1;

	& > section {
		width: 42px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
		color: #6c757d;
		flex: none;
		flex-grow: 0;
		z-index: 0;
		cursor: pointer;

		&:hover {
			color: #007bff;
		}
	}

	& > span {
		// position: absolute;
		height: 40px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 2px solid #007bff;
		flex: none;
		flex-grow: 0;
		z-index: 1;
	}
}

.componentOneFrameTwoInfo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 24px;
	gap: 10px;
	isolation: isolate;
	width: 204px;
	height: 48px;
	flex: none;
	flex-grow: 1;

	& > section {
		width: 86px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
		color: #6c757d;
		flex: none;
		flex-grow: 0;
		z-index: 0;
		cursor: pointer;

		&:hover {
			color: #007bff;
		}
	}

	& > span {
		position: absolute;
		height: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid #ced4da;
		flex: none;
		flex-grow: 0;
		z-index: 1;
	}
}

.componentOneFrameThree {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	width: 408px;
	height: 424px;
	flex: none;
	flex-grow: 0;
}

.componentOneFrameThreeOne {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 16px;
	isolation: isolate;
	width: 408px;
	height: 212px;
	background: white;
	flex: none;
	flex-grow: 0;

	& > span {
		position: absolute;
		height: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid #ced4da;
		flex: none;
		flex-grow: 0;
		z-index: 2;
	}
}

.componentOneFrameThreeOneInfoID {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;
	width: 360px;
	height: 22px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 17px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
		color: #6c757d;
		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 331px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		text-align: right;
		letter-spacing: -0.03em;
		color: #212529;
		flex: none;
		flex-grow: 1;
	}

	& > span {
		width: 0px;
		height: 360px;
		border: 1px solid #ced4da;
		transform: rotate(-90deg);
		flex: none;
		align-self: stretch;
		flex-grow: 0;
	}
}

.componentOneFrameThreeOneInfoStart {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;
	width: 360px;
	height: 22px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 123px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
		color: #6c757d;
		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 225px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		text-align: right;
		letter-spacing: -0.03em;
		color: #212529;
		flex: none;
		flex-grow: 1;
	}

	& > span {
		width: 0px;
		height: 360px;
		border: 1px solid #ced4da;
		transform: rotate(-90deg);
		flex: none;
		align-self: stretch;
		flex-grow: 0;
	}
}

.componentOneFrameThreeOneInfoBudget {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;
	width: 360px;
	height: 22px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 124px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
		color: #6c757d;
		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 224px;
		height: 22px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		text-align: right;
		letter-spacing: -0.03em;
		color: #212529;
		flex: none;
		flex-grow: 1;
	}
}

// booking sidebar info style
.sideBar {
	position: absolute;
	width: 408px;
	left: 0px;
	top: 120px;
	bottom: 0px;
	overflow-y: scroll;

	background: #ffffff;
}

.infoGroupselect {
	position: absolute;
	width: 360px;
	height: 328px;
	left: 24px;
	top: 516px;
}

.infoGroup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
	position: absolute;
	width: 360px;
	height: 328px;
	left: 24px;
	top: 516px;
}

.infoGropupLine {
	width: 360px;
	height: 0px;
	border: 1px solid #ced4da;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.IDGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
	width: 360px;
	height: 24px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 20px;
		height: 24px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.03em;
		color: #6c757d;
		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 324px;
		height: 24px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: right;
		letter-spacing: -0.03em;
		color: #212529;
		flex: none;
		flex-grow: 1;
	}
}

.MediaFormatGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 118px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 226px;
		height: 24px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		text-align: right;
		letter-spacing: -0.04em;
		text-decoration-line: underline;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.typeGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 32px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 312px;
		height: 24px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		text-align: right;
		letter-spacing: -0.04em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.sizeGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 60px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 284px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		text-align: right;
		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.reklaWatchGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 124px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 220px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		text-align: right;
		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.longWatchGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 161px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 183px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		text-align: right;
		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.graphicWatchGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 122px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 222px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		text-align: right;
		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.locationGroup {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 48px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		width: 162px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}

	& > h2 {
		width: 182px;
		height: 48px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* or 150% */

		text-align: right;
		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 1;
	}
}

.importanInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;

	position: absolute;
	width: 408px;
	height: 252px;
	left: 0px;
	top: 0px;
}

.infoOne {
	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 16px;

	width: 408px;
	height: 204px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.infoOneOne {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 16px;

	width: 360px;
	height: 56px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.Pin {
	width: 56px;

	/* Global/Primary */

	background: #007bff;
	/* Text/White */

	border: 2px solid #ffffff;
	border-radius: 40px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;
}

.stateAdv {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 68px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 4px 8px;

		width: 68px;
		height: 24px;

		/* Global/Primary */

		background: #007bff;
		border-radius: 4px;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;

		& > h1 {
			width: 52px;
			height: 16px;

			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			/* identical to box height, or 114% */

			letter-spacing: -0.03em;

			/* Text/White */

			color: #ffffff;

			/* Inside auto layout */

			flex: none;
			flex-grow: 0;
		}
	}
}

.infoOneTwo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;
	isolation: isolate;

	width: 360px;
	height: 84px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;

	& > h1 {
		width: 360px;
		height: 84px;

		/* Heading/H5 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		/* or 140% */

		display: flex;
		align-items: center;
		letter-spacing: -0.04em;

		/* Text/01. Body */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
		z-index: 0;
	}
}

.InfoTwo {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 408px;
	height: 48px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.InfoTwoOne {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 24px;
	gap: 10px;
	isolation: isolate;

	width: 204px;
	height: 48px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 1;

	& > h1 {
		width: 42px;
		height: 22px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
		z-index: 0;
	}

	& > span {
		position: absolute;
		height: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;

		/* Global/Global/Line */

		border: 1px solid #ced4da;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
		z-index: 1;
	}
}

.InfoTwoTwo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 24px;
	gap: 10px;
	isolation: isolate;

	width: 204px;
	height: 48px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 1;

	& > h1 {
		width: 86px;
		height: 22px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */

		letter-spacing: -0.03em;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
		z-index: 0;
	}

	& > span {
		position: absolute;
		height: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;

		/* Global/Primary */

		border: 2px solid #007bff;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
		z-index: 1;
	}
}

.imageFrame {
	position: absolute;
	height: 240px;
	left: 0px;
	right: 0px;
	top: 252px;

	background: #f5f5f5;

	& > img {
		position: absolute;
		width: 408px;
		height: 240px;
		left: 0px;
		top: 0px;
	}
}

// Bookings maps Baloon style
.bilboardBaloon {
	// position: absolute;
	width: 240px;
	height: 78px;
	left: 796px;
	top: 665px;

	filter: drop-shadow(0px 4px 4px rgba(33, 37, 41, 0.15));
}

.baloonElipse {
	position: absolute;
	width: 8px;
	height: 8px;
	left: calc(50% - 8px / 2);
	bottom: -4px;

	background: #ffffff;
	border: 2px solid #ced4da;
	border-radius: 50px;
}

.baloonInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 4px;

	position: absolute;
	height: 57px;
	width: 200px;
	left: 50px;
	right: 16px;
	top: 7px;
	border-radius: 4px;
}

.baloonType {
	width: 168px;
	height: 20px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: -0.04em;
	color: #212529;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.baloonBookingCount {
	width: 168px;
	height: 16px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	letter-spacing: -0.03em;

	/* Global/Primary */

	color: #007bff;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;
}

.baloonIcon {
	position: absolute;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	/* Global/Primary */

	background: #007bff;
	border-radius: 40px;
}

.baloonIconBilboard {
	position: absolute;
	width: 24px;
	height: 24px;
	left: 8px;
	top: 7px;
}

.baloonUnion {
	position: absolute;
	width: 240px;
	height: 62px;
	left: 0px;
	top: 0px;
	border-radius: 5px;

	border: 2px solid #ced4da;
	background: #ffffff;
	z-index: -100;
}

.baloonReactangle {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 22px;

	border-radius: 4px;
}

.baloonVector {
	box-sizing: border-box;
	position: absolute;
	width: 14px;
	left: calc(50% - 14px / 2);
	bottom: -5px;
	/* Изменено значение на 12px */
	border-top: 6px solid white;
	border-right: 3px solid transparent;
	border-left: 3px solid transparent;
}

.grayBillboard {
	color: #6c757d;
}

.grayBillboardd {
	background: #f5f5f5;
}

.rightSidebar {
	width: 408px;
	left: 408px;
	top: 136px;
	bottom: 0px;
	height: auto;
	background: #ffffff;
	overflow-x: hidden;
	overflow-y: hidden;

	:global(.modal-dialog) {
		margin: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: auto;
	}

	:global(.modal-content) {
		max-height: auto;
	}

	:global(.modal-dialog) {
		overflow-y: auto;
	}
}

.infoFrame {
	position: absolute;
	width: 360px;
	height: 328px;
	left: 24px;
	top: 516px;
}

.sideBarDivider {
	position: absolute;
	width: 904px;
	right: -904px;
	top: 904px;
	bottom: 0px;

	border: 1px solid #ced4da;
	transform: rotate(-90deg);
}

.rightSidebarButtonFrame {
	position: relative;
	background: #ffffff;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	width: 100%;
}

.buttonsContainerOne {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 17px 12px;

	width: 111px;
	height: 80px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;

	& > h1 {
		width: 87px;
		height: 22px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */

		text-align: center;
		letter-spacing: -0.04em;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}
}

.buttonsContainerTwo {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 17px 12px;

	width: 95px;
	height: 80px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;

	& > h1 {
		width: 71px;
		height: 22px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */

		text-align: center;
		letter-spacing: -0.04em;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}
}

.buttonsContainerThree {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 17px 12px;

	width: 94px;
	height: 80px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;

	& > h1 {
		width: 70px;
		height: 22px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */

		text-align: center;
		letter-spacing: -0.04em;

		/* Global/Error */

		color: #dc3545;

		/* Inside auto layout */

		flex: none;
		flex-grow: 0;
	}
}

.rightSidebarHeaderText {
	/* Heading/H5 */

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	/* identical to box height, or 140% */

	display: flex;
	align-items: center;
	letter-spacing: -0.04em;

	/* Text/01. Body */

	color: #212529;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;
	margin: 0;
}

.rightSidebarHeaderContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
	flex: none;
	flex-grow: 0;
}

.rightSidebarBadge {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 113px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;
}

.rightSidebarAdvertisingGropup {
}

.rightSidebarAdvertisingGropupHeader {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: #212529;
}

.rightSidebarAdvertisingGropupContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
	margin-bottom: 16px;
}

.rightSidebarClientGroup {
	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		/* Heading/H6 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;
	}
}

.rightSidebarClientGroupContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	margin-bottom: 16px;
}

.rightSidebarDownloadGroup {
	padding-bottom: 10px;
	position: relative;

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	& > h1 {
		/* Heading/H6 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;
	}

	&:after {
		border-bottom: 1px solid #ced4da;
		position: absolute;
		bottom: 0;
		left: 8px;
		right: 8px;
		content: '';
	}
}

.closeFrame {
	position: absolute;
	width: 64px;
	height: 64px;
	right: -37px;
	top: -67px;
	background: #ffffff;
	cursor: pointer;

	&:hover svg {
		transform: scale(1.1);
		transition: transform 0.2s ease-in-out;
	}
}
