.mediaRequirementsContainer {
	padding: 1rem 1.5rem;
}
.mediaRequirements {
	display: flex;
	flex-direction: column;
}
.mediaRequirements > div:not(:last-child) {
	border-bottom: 1px solid #ced4da;
}
.mediaRequirementsField {
	display: flex;
	width: 100%;
	align-self: center;
	justify-content: space-between;
	padding: 0.5rem 0;

	span {
		color: #6c757d;
	}

	p {
		margin: 0;
		text-align: right;
	}
}
.bookingEditWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.bookingEditContainer {
	padding: 1rem;
}
.bookingEditWrapperFooter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1rem;
	gap: 1rem;
	border: 1px solid #ced4da;
}
.collapseButton {
	margin-left: 0.5rem;
	align-self: flex-start;
	width: 40px;
	height: 38px;
	align-items: center;
	justify-content: center;
	padding: 0;
	background-color: inherit;
	border: none;
	border-radius: 12px;
	padding-bottom: 2px;
	svg,
	i {
		fill: #6c757d;
		color: #6c757d;
	}

	&:hover {
		background-color: #f5f5f5;
	}
}
//mobile view
@media only screen and (max-width: 900px) {
	.mediaRequirementsContainer {
		padding: 1rem;
		flex: 1 1;
		overflow: scroll;
	}
	.bookingEditWrapper {
		height: 100%;
		max-height: 100%;
	}
	.mediaRequirementsField {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}
}
