.advTypeSummaryInfo {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	border: none;
	border-radius: 1.5rem;
	padding: 24px 40px;
	h5 {
		margin: 0;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.advTypeSummaryInfo {
		padding: 1rem;

		h5 {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}
}
