.placeholder {
	padding: 24px 0;
	text-align: center;
}

.bookingItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px 24px 16px 24px;
	z-index: 1;
	cursor: pointer;
	border-bottom: 1px solid #ced4da;

	&:hover {
		background-color: #f5f5f5;
	}

	&.active {
		border-right: 1px solid #007bff;
	}
}

.bookingTop {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
	width: 360px;
	height: 50px;
	flex: none;
	flex-grow: 0;
	z-index: 0;
	margin-bottom: 8px;

	& > h6 {
		width: 360px;
		height: 24px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.03em;
		color: #212529;
		flex: none;
		flex-grow: 0;
		margin: 0;
	}
}

.bookingTopLine {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 3px 4px;
		border-radius: 4px;
		flex: none;
		flex-grow: 0;

		& > h1 {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			text-align: right;
			color: #ffffff;
			flex: none;
			flex-grow: 0;
			margin: 0;
		}
	}
}

.moreFrame {
	border-radius: 4px;
	&:hover {
		background-color: white;
		// .more {
		// 	transform: scale(1.2);
		// }
	}
}
