.notificationAlertContainer {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 100000;
}

.notification {
  background: #FFFFFF;
  width: 480px;
  max-width: 480px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  box-shadow: 0 6px 6px rgba(33, 37, 41, 0.15);
  border-radius: 4px;
  padding: 16px 52px 16px 20px;
  margin-bottom: 8px;
  position: relative;
  @media (max-width: 600px) {
    width: 288px;
  }
}
.notificationText {
  font-family: Inter,'sans-serif';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #212529;
  cursor: default;
  a {
    text-decoration: none;
    color: #007BFF;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }
}

.closeBtn {
  position: absolute;
  padding: 5px 8px;
  top: 5px;
  right: 10px;
  cursor: pointer;
}