.registerInfo {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: center;
	text-align: center;

	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
	color: #949494;

	a,
	span {
		cursor: pointer;
	}

	a {
		text-decoration: none;
	}

	span {
		color: #007bff;
	}
}
