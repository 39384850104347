.header {
	display: flex;
	flex-direction: column;
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: -0.03em;
	text-align: left;

	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
	}
}
.linesWrapper {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.line {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.info {
	width: 50%;
	overflow: hidden;
	div {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: -0.03em;
		color: #6c757d;
	}
	span {
		font-family: Inter;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: -0.03em;
	}
}
