.workMode {
	display: flex;
	justify-content: space-between;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #ced4da;
	margin-top: 12px;

	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #6c757d;
	}
	p {
		margin: 0;

		font-size: 14px;
		line-height: 22px;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.workMode {
		padding-bottom: 6px;
		margin-top: 6px;
		span {
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
		}
		P {
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
		}
	}
}
