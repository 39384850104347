.campaignItemInfo {
}

.campaignItemInfoHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	h5 {
		//styleName: Heading/H5;
		font-family: Inter;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.04em;
		text-align: left;
	}
	> div {
		height: min-content;
		border-radius: 4px;
		padding: 3px 4px;
		color: white;
	}
}
.campaignItemInfoField {
	display: flex;
	height: auto;
	justify-content: space-between;
	padding: 8px 0;
	border-bottom: 1px solid #ced4da;
}
.campaignItemInfoFieldName {
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: left;
	color: #6c757d;
}
.campaignItemInfoFieldValue {
	word-wrap: break-word;
	max-width: 70%;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: right;
}

.accordionHeader {
	display: flex;
	align-items: center;
	gap: 16px;

	div {
		display: flex;
		gap: 0.5rem;
	}
	h6 {
		margin: 0;
		margin-left: 0.5rem;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: left;
	}
	button {
		padding: 12px;
		background-color: #ced4da;
		color: #212529;
	}
	button:not(.collapsed) {
		color: #212529;
		background-color: #f5f5f5;
	}
	button:not(.collapsed)::after {
		background-image: url(../../../../../../../assets/images/select-hide.svg);
	}
}
.imageTitle {
	width: 100%;
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: flex-start;

	img {
		border-radius: 4px;
		width: 64px;
		height: 64px;
	}
	h6 {
		margin: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: left;
	}
}
.advItemInfo {
	display: flex;
	width: 100%;
}
.advItemInfoColumn {
	display: flex;
	gap: 8px;
	width: 50%;
	flex-direction: column;
}
.advItemInfoField {
	display: flex;
	gap: 16px;
	align-items: center;
	span {
		min-width: 60px;
		color: #6c757d;
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.03em;
		text-align: left;
	}
	div {
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.03em;
		text-align: left;
	}
}
.advItemInfoField_internet {
	span {
		min-width: 80px;
	}
	div {
		min-width: 300px;
	}
}
.mediaCreativePreview {
	display: flex;
	justify-content: flex-start;
	gap: 16px;
	align-items: center;
	> div {
		span {
			min-width: 60px;
			color: #6c757d;
			font-family: Inter;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: -0.03em;
			text-align: left;
		}
		div {
			font-family: Inter;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: -0.03em;
			text-align: left;
		}
	}
}

.tooltipButtons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.accordionBody {
	padding: 0;
	border-bottom: 1px solid #ced4da;
	> div {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
	}
}
.moderationButtons {
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	padding: 6px 0;
	border-radius: 4px;
	gap: 6px;
	max-width: 50%;
	flex-grow: 1;
	justify-content: center;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: center;

	svg {
		width: 16px;
		height: 16px;
	}
	i {
		width: 16px;
		height: 16px;
	}
	p {
		margin: 0;
	}

	&:hover {
		background-color: #ced4da;
	}
}
.moderationButtons_iconsOnly {
	border: none;
	display: flex;
	background-color: none;
	justify-content: center;
	align-items: center;
	background-color: none;
	border-radius: 4px;
	margin: 0 !important;
	width: 36px;
	height: 36px;
	padding: 0;
	background-color: inherit;

	&:hover {
		background-color: #f5f5f5;
	}
}

@media only screen and (max-width: 600px) {
	.campaignItemInfoFieldName,
	.campaignItemInfoFieldValue {
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.05em;
	}
}
