//Главный контейнер компонента
.container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	background-color: #ffffff;
	min-height: 100%;
	width: 100%;
}

//Сообщение о зоне работы
.areaAlert {
	padding: 40px 40px 0 40px;
	& > div {
		margin-bottom: 0;
	}
	@media (max-width: 1132px) {
		padding: 24px 24px 0 24px;
	}
	@media (max-width: 600px) {
		padding: 16px 16px 0 16px;
		& > div {
			flex-wrap: wrap;
			justify-content: center;
			text-align: left;
			padding: 12px;
			a {
				margin-top: 12px;
				margin-left: 0 !important;
			}
		}
	}
}

//Хедер с вкладками
.header {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	padding: 18px 40px 18px 40px;
	h1 {
		margin-bottom: 0;
	}
	@media (max-width: 1132px) {
		padding: 24px 24px 16px 24px;
	}
	@media (max-width: 600px) {
		padding: 0px 20px 16px 20px;
		flex-direction: column;
		text-align: center;
		gap: 16px;
	}
	button {
		height: fit-content;
	}
}
//табы
.tabs {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	color: #6c757d;
	letter-spacing: -0.05em;
	margin-top: 3px;
	padding-left: 40px;
	overflow-x: auto;

	overflow-y: hidden;
	@media (max-width: 1132px) {
		padding-left: 24px;
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar {
		height: 3px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(33, 37, 41, 0.3);
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(33, 37, 41, 0.5);
		border-radius: 10px;
	}
}
.tab {
	padding: 9px 16px 7px 16px;
	border-bottom: 2px solid transparent;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	white-space: nowrap;

	span {
		height: 18px;
		border-radius: 4px;
		padding: 3px 4px 3px 4px;
		margin-left: 4px;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.05em;
	}
}
//мобильные табы
.mobileTabToggle {
	display: flex;
	justify-content: space-between;
	padding: 7px 16px 8px 16px;
}
.currentMobileTab {
	flex: 1 1 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: #007bff;
	font-size: 16px;
	span {
		height: 18px;
		border-radius: 4px;
		padding: 3px 4px 3px 4px;
		margin-left: 4px;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.05em;
	}
}
.MobileTab {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	height: 40px;

	span {
		height: 18px;
		border-radius: 4px;
		padding: 3px 4px 3px 4px;
		margin-left: 4px;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.05em;
	}
}

//Десктоп контейнер для списка кампаний
.tableContainer {
	padding: 24px;
	@media (max-width: 1132px) {
		padding: 16px 8px;
	}
	@media (max-width: 600px) {
		padding: 0;
	}
}

//Десктоп отображение кампаний в виде таблицы
.campaignTable {
	width: 100%;
	border-collapse: collapse;

	thead {
		tr {
			border-bottom: 1px solid #ced4da;
		}
		tr > th {
			font-family: 'Inter', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.04em;
			color: #6c757d;
			padding-left: 16px;
			padding-bottom: 16px;
		}
	}

	tbody {
		tr:first-of-type {
			margin-top: 8px;
		}
		tr:hover {
			td {
				background-color: #f5f5f5;
			}
			td:first-of-type {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			td:last-of-type {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
		tr > td {
			padding: 16px;
			font-family: 'Inter', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.04em;
			color: #212529;
			cursor: pointer;
			span {
				height: 18px;
				border-radius: 4px;
				padding: 3px 4px 3px 4px;
				margin-left: 4px;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: -0.05em;
			}
		}
	}
}

//Планшет контейнер для списка кампаний
.tabletContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 16px 8px 28px 8px;
}

//Планшетное представление кампании
.tabletCampaign {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 16px 16px 13px 16px;
}
.tabletTitleContainer {
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 12px;
}

//Мобильное представление кампании
.mobileCampaign {
	padding: 16px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}
.trash {
	cursor: pointer;
	border-radius: 4px;
	padding: 6px 10px;
	i {
		color: #6c757d;
	}
}
.trash:hover {
	background: #f5f5f5;
	i {
		color: #dc3545;
	}
}
.draft {
	cursor: pointer;
	border-radius: 4px;
	padding: 6px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 36px;
	svg > path {
		fill: #6c757d;
	}
}
.draft:hover {
	background: #f5f5f5;
	svg > path {
		fill: #007bff;
	}
}

//Когда нет кампаний

.emptyCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1 1 0;
}

.emptyIcon {
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 16px;
	display: block;
	text-align: center;
	@media (max-width: 600px) {
		font-size: 28px;
		line-height: 36px;
		margin-bottom: 16px;
		display: block;
		text-align: center;
		color: #212529;
	}
}

.emptyText {
	color: #6c757d;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 40px;
	text-align: center;
	@media (max-width: 600px) {
		color: #6c757d;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 30px;
		text-align: center;
	}
}
