.signInHeader {
	text-align: center;
	margin-bottom: 2.25rem;
}

.authForm {
}
.authActions {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.authExtraActionsContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

//mobile view
@media only screen and (max-width: 600px) {
	.signInHeader {
		margin-bottom: 2rem;
	}
	.authForm {
		height: 100%;
		justify-content: space-between;
	}
	.authActions {
		gap: 0.75rem;
	}
	.authExtraActionsContainer {
		flex-direction: column-reverse;
		gap: 0.75rem;
	}
}
