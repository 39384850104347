.backButton {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	p {
		margin: 0;
	}
}
.info {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.infoFieldsHeader {
	padding: 1.5rem 1.5rem 0 1.5rem;
	h5 {
		line-height: 28px;
		margin: 0;
	}
	p {
		margin: 0;
		color: #6c757d;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
	}
}
.infoFieldsHeader_primal {
	display: flex;
	justify-content: space-between;

	button {
		width: 24px;
		height: 24px;
		border-radius: 4px;
		background-color: #f5f5f5;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		i {
			color: #6c757d;
		}
	}
}
.infoFields {
	padding: 0 1.5rem 1.5rem 1.5rem;
}
.actionButton {
	width: 100%;
	padding: 1rem 1.5rem;

	button {
		width: 100%;

		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: -0.05em;
	}
}
//mobile  view
@media only screen and (max-width: 600px) {
	.infoFieldsHeader {
		padding: 1rem 1rem 0 1rem;
		p {
			font-size: 12px;
			line-height: 20px;
		}
	}
	.infoFields {
		padding: 0 1rem 1rem 1rem;
	}

	.actionButton {
		width: 100%;
		padding: 0 1rem 1rem 1rem;

		button {
			width: 100%;

			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.03em;
			text-align: center;
		}
	}
}
