.bookingTable {
	margin-top: 20px;

	border-collapse: collapse;
	border-spacing: 0;

	border: 1px solid #ced4da;

	overflow-x: scroll;
	th,
	td {
		border: 1px solid #ced4da;

		div {
			height: 100%;
			padding: 12px 16px;
			p {
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
			}
		}
	}

	thead {
		tr {
			background-color: #f5f5f5;
			th {
				div {
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
				}
			}
		}
	}

	tbody {
		td {
			height: 96px;
			max-width: 340px;
		}
	}
}
::-webkit-scrollbar {
	height: 5px; /* Set the height of the horizontal scrollbar */
}
