.advType {
	border: 1px solid #007bff;
	border-radius: 8px;
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #007bff;
	fill: #007bff;

	&:hover {
		background-color: #f5f5f5;
		border-color: #ced4da;
		color: #212529;
		fill: #212529;
	}

	> div {
		display: flex;
		align-items: center;
		gap: 16px;

		svg {
			fill: inherit;
		}

		h6 {
			color: inherit;
			margin: 0;
			font-family: Inter;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.03em;
			text-align: left;
		}
	}
}
.formingDocsList {
	display: flex;
	flex-direction: column;
	padding: 24px;
	gap: 24px;

	// стиль для дочерних элементов первого уровня
	> * {
		padding-bottom: 24px;
		border-bottom: 1px solid #ced4da;

		&:last-child {
			padding: 0;
			border: none;
		}
	}
}

.formingDocsItem {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.adjusmentMessage {
	display: flex;
	flex-direction: column;
	gap: 16px;

	border-bottom: 1px solid #ced4da;
	padding-bottom: 16px;

	h6 {
		color: #007bff;
		margin: 0;
	}
}
.docsView {
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-bottom: 1px solid #ced4da;
	fill: #007bff;
	ul {
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 8px;
		li {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}

	> div {
		color: #007bff;
		padding: 6px 12px;
		font-weight: 500;
	}
}
