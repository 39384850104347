.typeSwitcherWrapper {
	padding-bottom: 40px;
}

.typeSwitcherContainer {
	display: flex;
	gap: 1rem;
}
.typeSwitcherButton {
	height: 4rem;
	min-width: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	gap: 16px;
	border-radius: 0.5rem;
	padding: 1rem;
	border: none;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
	}

	h5 {
		margin: 0;
	}

	svg {
		width: 2rem;
		height: 2rem;
		fill: #212529;
	}
}
.typeSwitcherButton_enabled {
	&:hover {
		svg {
			fill: #007bff;
		}

		background-color: rgba(0, 123, 255, 0.1);
	}
}
.typeSwitcherButton_active {
	background-color: #007bff;
	color: #ffffff;
	flex-grow: 1;
	fill: #ffffff;

	h5 {
		color: #ffffff;
	}

	svg {
		fill: #ffffff;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.typeSwitcherWrapper {
		padding-bottom: 1rem;
	}

	.typeSwitcherContainer {
		gap: 0.25rem;
	}

	.typeSwitcherButton {
		min-width: 33.75px;
		height: 40px;
		padding: 12px;
		gap: 4px;

		h5 {
			font-size: 10px;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: -0.02em;
		}

		svg {
			width: 1rem;
			height: 1rem;
		}
	}
}
