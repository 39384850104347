.clientSidebar {
	position: fixed;
	top: 65px;
	height: calc(100vh - 65px);
	//height: 100%;
	z-index: 999;
	background: #212529;
	border-right: 1px solid #ced4da;
	//padding: 23px 16px 24px 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 240px;
	color: #ffffff;
	left: -240px;
	transition: all 0.5s ease-in-out;
	@media screen and (max-width: 600px) {
		top: 51px;
		height: calc(100vh - 51px);
	}
}

.clientSidebar_force {
	left: 0;
	z-index: 999;
}
.clientSidebar_absolute {
	//position: absolute;
	//top: 0px;
}

.tooltip-inner {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	letter-spacing: -0.05em;
	max-width: 250px;
}
