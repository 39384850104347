.body {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	/*max-height: calc(100vh - env(safe-area-inset-bottom));*/
	/*min-height: calc(100vh - env(safe-area-inset-bottom));*/
	z-index: 1000;
	background: white;
	-webkit-overflow-scrolling: touch;
	border: none;
	padding-bottom: 4em;
}
.touch {
	position: fixed;
	top: 40px;
	left: 0;
	background: transparent;
	width: 40px;
	height: calc(100vh - env(safe-area-inset-bottom));
	z-index: 2000;
}
.header {
	position: relative;
	min-height: 40px;
	max-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #ced4da;
}
.content {
	overflow-y: auto;
	padding: 1em;
	width: 100%;
	max-height: 100%;
	height: 100%;
}
:root {
	--sat: env(safe-area-inset-top);
	--sar: env(safe-area-inset-right);
	--sab: env(safe-area-inset-bottom);
	--sal: env(safe-area-inset-left);
}
