.feedback__label {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 42px;

	padding: 8px 12px;
	border-radius: 5px;
	font-family: Inter;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: -0.02em;
	text-align: center;
	border: 1px solid #ced4da;
	color: #fff;
	cursor: pointer;

	background-color: #007bff;
}
.labelDisabled {
	background-color: #f5f5f5;
	color: #6c757d;
}
.feedback__file {
	display: none;
}
.labelError {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: red;
}
