.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.title {
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.02em;
}
.budgets {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ced4da;
}

.budgetItem {
	display: flex;
	gap: 16px;
}

.budgetVariantsList {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	flex: 2;
}

.format {
	flex: 1;
}
.budgetItemVariant {
	display: flex;
	justify-content: space-between;

	h6 {
		margin: 0;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}

.summary {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.summaryItem {
	display: flex;
	justify-content: space-between;

	h6 {
		margin: 0;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
}
