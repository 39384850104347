.adstreamCard {
	padding: 40px;
	background-color: #ffffff;
	border-radius: 8px;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.adstreamCardBody {
	display: flex;
	gap: 20px;
}

.adstreamStateWrapper {
	width: 50%;
}

.adstreamInputWrapper {
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.adstreamActionsWrapper {
	display: none;
}

.adstreamRequirementsTitle {
	display: flex;
	align-items: center;
	padding: 2rem;
	margin: 0;

	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.03em;
}

//mobile view
@media only screen and (max-width: 600px) {
	.adstreamCard {
		padding: 1rem;
		gap: 1rem;
	}
	.adstreamStateWrapper {
		width: 100%;
	}
	.adstreamInputWrapper {
		display: none;
	}
}
