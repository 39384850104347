.massCoverageItem {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1rem;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid #ced4da;
}

.massCoverageItemControl {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}
.controlInputs {
	display: flex;
	gap: 1rem;
	width: 100%;
}
.controlCity {
	flex: 2;
}
.controlBudget {
	flex: 1;
}
.controlCity,
.controlBudget {
	label {
		line-height: 24px;
		letter-spacing: -0.03em;
	}
}

.removeItemButton {
	align-self: center;
	border-radius: 4px;
	margin-top: 0.5rem;
	&:hover {
		i {
			color: #dc3545;
		}
	}
}
.removeItemButton_recBudget {
	margin-top: 0;
}
.openMapButtonWrapper {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	width: 100%;

	h6 {
		margin: 0;
		line-height: 24px;
		letter-spacing: -0.03em;
	}
}
.openMapButton {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	gap: 4px;

	svg {
		fill: #fff;
	}

	p {
		margin: 0;
		line-height: 24px;
		letter-spacing: -0.03em;
		font-weight: 500;
	}

	&:disabled {
		svg {
			fill: #6c757d;
		}
	}
}

//tabs view
@media only screen and (max-width: 850px) {
	.controlInputs {
		flex-direction: column;
	}
	.removeItemButton {
		align-self: center;
		margin-top: 2rem;
	}
	.removeItemButton_recBudget {
		margin-top: 0.5rem;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.massCoverageItem {
		padding-bottom: 1rem;
	}
	.removeItemButton {
		align-self: center;
	}
	.openMapButtonWrapper {
		justify-content: space-between;

		h6 {
			font-size: 14px;
			line-height: 20px;
		}
	}
	.openMapButton {
		p {
			font-size: 14px;
		}
	}
}
