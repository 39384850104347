.container {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 8px;
	max-width: 100vw;
}
.tabContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
	flex: 1 1 auto;
}

.clearBtn {
	background: #f5f5f5;
	padding: 6px 12px;
	height: fit-content;
	&:hover {
		background: #e9ecef;
	}
	&:active,
	&:focus {
		outline: none !important;
		box-shadow: none !important;
		//border: 2px solid lightblue;
	}

	border-radius: 4px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;
	color: #212529;
}

.tab {
	background: #f5f5f5;
	height: fit-content;
	padding: 7px 12px;
	display: flex;
	cursor: pointer;
	overflow: hidden;
	&:hover {
		background: #e9ecef;
	}
	border-radius: 4px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.04em;
	color: #6c757d;
	white-space: nowrap;
	overflow-x: auto;
	span {
		color: #212529;
		margin-left: 4px;
		max-width: 700px;
		overflow-x: scroll;
		//text-overflow: ellipsis;
		&::-webkit-scrollbar {
			height: 0px !important; /* Remove scrollbar space */
			background: transparent; /* Optional: just make scrollbar invisible */
		}
		/* Optional: show position indicator in red */
		&::-webkit-scrollbar-thumb {
			border-radius: 0 !important;
			background: transparent; /* Optional: just make scrollbar invisible */
		}
		&::-webkit-scrollbar-track {
			border-radius: 0 !important;
		}
	}

	.close {
		height: 100%;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 4px;
		cursor: pointer;
		border-radius: 4px;
		&:hover {
			transform: scale(1.2);
			background: rgb(225, 228, 231);
		}
	}
}

//Modal
.filterType {
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;
	span {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.04em;
		color: #212529;
	}
	div {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #f5f5f5;
		text-align: center;
	}
}
.filterTypeActive {
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	cursor: pointer;
	background-color: #f5f5f5;
	transition: all 0.4s ease-in-out;
	span {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.04em;
		color: #212529;
	}
	div {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #ffffff;
		text-align: center;
	}
}
