.bookingAdContainer {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr;
	@media (max-width: 600px) {
		gap: 8px;
	}
}
.bookingBudget {
	padding: 24px 40px;
	display: flex;
	justify-content: space-between;
	border-radius: 8px;
	background-color: #ffffff;
	h5 {
		margin-bottom: 0;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: -0.04em;
	}
	@media (max-width: 600px) {
		padding: 16px;
		align-items: center;
		h5 {
			font-size: 14px;
			line-height: 20px;
		}
	}
}

//Booking Card
.bookingCard {
	display: flex;
	padding: 40px;
	background-color: #ffffff;
	border-radius: 8px;
	flex-direction: column;
	overflow: hidden;
}
.title {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	> div > img {
		width: 80px;
		height: 80px;
		max-width: 80px;
		max-height: 80px;
		object-fit: cover;
		border-radius: 4px;
		margin-right: 24px;
		overflow: hidden;
	}
	h5 {
		white-space: break-spaces;
	}
}

//MobileBookingCard
.bookingCardMobile {
	padding: 16px;
	background-color: #ffffff;
	border-radius: 8px;
	position: relative;
}
.mobileDetailsBtn {
	width: 48px;
	height: 48px;
	display: grid;
	place-items: center;
	position: absolute;
	top: 0;
	right: 0;
	background: #ffffff !important;
	border: none;
	border-radius: 8px;
}

//Booking Calendar
.bookingCalendarContainer {
	position: relative;
}
.calendarNavButton {
	position: absolute;
	top: 20px;
	cursor: pointer;
	z-index: 2;
}
.calendarNextMonth {
	right: 40px;
	transform: rotate(180deg);
	top: 23px;
}

.calendarPrevMonth {
	left: 40px;
}

.calendarNextYear {
	right: 16px;
	top: 23px;
	transform: rotate(180deg);
}

.calendarPrevYear {
	left: 16px;
}
.calendarDay {
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	background-color: #007bff;
	outline: 1px solid #007bff;
	color: #ffffff;
}
.calendarDayGray {
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	background-color: #e1e1e1;
	color: #212529;
	outline: 1px solid #e1e1e1;
}
.calendarSeparator {
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: #ced4da;
	top: 62px;
	z-index: 2;
}

.trashBtn {
	&:hover {
		background-color: #dc3545;
		svg path {
			fill: #ffffff;
		}
	}
}
