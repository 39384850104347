.dateInput {
	height: 36px;
	background: #f5f5f5;
	border-radius: 4px;
	cursor: pointer;
	position: relative;
	padding: 6px 12px;
	outline: none;
	border: none;
	color: #212529;

	white-space: nowrap;
	&:hover {
		background-color: #dadada;
	}
	&:focus-visible {
		border: 1px solid #005ce1;
	}
}
