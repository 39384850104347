.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #ffffff;
	height: calc(100vh - 65px);
	@media (max-width: 600px) {
		height: calc(100vh - 51px);
	}
}
.mobilePageTitle {
	padding: 20px 24px;
	border-bottom: 1px solid #ced4da;
	h4 {
		margin: 0;
	}
	@media (max-width: 600px) {
		padding: 12px 16px;
	}
}
.filtersContainer {
	display: flex;
	flex-direction: row;
	padding: 24px 40px;
	gap: 16px;
	height: fit-content;
	width: 100%;

	div > input {
		min-width: 400px;
		padding-right: 50px;
	}
	div > i {
		right: 20px;
		top: 9px;
		cursor: text;
		pointer-events: none;
		color: #6c757d;
	}
	@media (max-width: 1132px) {
		padding: 24px;
		div > input {
			min-width: 310px;
		}
	}
	@media (max-width: 600px) {
		flex-direction: column;
		padding: 16px;
		gap: 8px;
		div > input {
			min-width: 100%;
		}
	}
}

.desktopTableRow {
	cursor: pointer;
	& > td {
		padding: 16px;
		border-radius: 4px;
	}
	&:hover {
		background-color: #f5f5f5;
	}
}

.desktopTableHeader {
	font-size: 14px;
	color: #6c757d;
	position: sticky;
	top: -1px;
	background-color: #ffffff;
	& > td {
		padding-bottom: 16px;
		padding-left: 15px;
	}
	border-bottom: 1px solid #ced4da;
	margin-bottom: 2px;
}
