.helpNavigator {
	position: relative;
	border-bottom: 1px solid #ced4da;
}
.helpNavigatorContent {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 24px;
	min-width: 142px;

	h4 {
		font-family: Inter;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.04em;
		text-align: left;
		margin: 0;
	}
}
.helpNavigatorContent_mobile {
	flex-direction: column;
	margin: 12px 16px;
	align-items: flex-start;
	gap: 10px;
	h4 {
		font-size: 20px;
		line-height: 28px;
	}

	button {
		width: 100%;
	}
}

.button {
	cursor: pointer;
	border: 0;
	border-radius: 8px;
	padding: 0;
}
.buttonContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 6px 12px;

	p {
		margin-right: 4px;
		margin-bottom: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: center;
	}

	svg {
		width: 16px;
		height: 16px;
	}
}
.buttonContent_mobile {
	justify-content: center;
}
.helpListWrapper {
	position: absolute;
	box-shadow: 0px 4px 4px 0px #21252926;
	border: 1px solid #ced4da;
	width: 280px;
	right: 0;
	top: 70px;
	border-radius: 8px;
	background-color: #fff;
	margin-right: 24px;
}
