.template_images_list_container {
	margin-top: 2.5rem;
	max-width: 50rem;
	margin-left: 1.5rem;
	position: relative;
	margin-right: 0;
}
.template_images_list_container_mobile {
	margin-top: 1.5rem;
	margin-right: 1rem;
}
