.container {
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
}
.bg {
	width: 100%;
	height: 100%;
	background: black;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
}
.sheet {
	position: fixed;
	width: 100vw;
	background: white;
	z-index: 1001;
	border-radius: 8px 8px 0 0;
	max-height: 50vh;
	display: flex;
	flex-direction: column;
	/*overflow: hidden;*/
}
.sheet::before {
	content: '';
	position: absolute;
	display: block;
	width: 56px;
	height: 2px;
	z-index: 999;
	left: calc(50% - 56px / 2);
	top: 4px;
	background: #ced4da;
	border-radius: 4px;
}
.sheet::after {
	position: absolute;
	content: '';
	left: 0;
	top: 99%;
	width: 100vw;
	height: 50vh;
	background: white;
}
.touch {
	width: 100%;
	min-height: 65px;
	max-height: 65px;
	position: absolute;
	background: transparent;
	z-index: 10;
}
.children {
	width: 100%;
	overflow-y: auto;
	height: auto;
	flex-grow: 1;
}
.footer {
	width: 100%;
	/*min-height: 50px;*/
	max-height: fit-content;
	/*height: auto;*/
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}
.close {
	position: absolute;
	width: 24px;
	height: 24px;
	right: 16px;
	top: 20px;
	background: #f5f5f5;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}
