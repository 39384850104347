.campaignCell {
	display: flex;
	flex-direction: column;
	gap: 4px;
	//styleName: Body/Paragraph 2;

	> span {
		color: #6c757d;
		//styleName: Body/Caption;
		font-family: Inter;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.03em;
		text-align: left;
	}
}
.noIssues {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	gap: 16px;

	margin: 0 auto;
	> svg {
		fill: #6c757d;
		width: 40px;
		height: 40px;
	}
	> p {
		color: #6c757d;
		font-family: Inter;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.04em;
		text-align: center;
	}

	@media (max-width: 600px) {
		gap: 8px;
		> svg {
			width: 20px;
			height: 20px;
		}
		> p {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.03em;
			text-align: center;
		}
	}
}
