.signUpForm {
	display: flex;
	flex-direction: column;
	gap: 2.25rem;
}
.signUpInputs {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.signUpInputsHelpText {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}
.signUpSubmit {
	width: 100%;
	border-radius: 1rem !important;
}

.signUpInfoContainer {
	margin-top: 1rem;
}
.signUpInfo {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: center;
	text-align: center;

	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
	color: #949494;

	a,
	span {
		cursor: pointer;
	}

	a {
		text-decoration: none;
	}

	span {
		color: #007bff;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.signUpForm {
		gap: 2rem;
	}
	.signUpInputs {
		gap: 1rem;
	}
	.signUpInfo {
		font-size: 12px;
		line-height: 20px;
		gap: 1rem;
	}
	.signUpSubmit {
		border-radius: 0.75rem !important;
	}
}
