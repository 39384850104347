.button {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;

	svg {
		color: '#212529';
		width: 1rem;
		height: 1rem;
	}

	&:hover {
		background-color: #f5f5f5;
		svg {
			fill: #007bff;
		}
	}
}
