.clientSidebar_hidden {
	position: absolute;
	width: 25px;
	height: inherit;
	background: transparent;
	left: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg {
		margin-bottom: 80px;

		path {
			fill: #6c757d;
		}
		transform: rotate(180deg);
	}
	&:hover {
		background: #e5e5e5;
		color: #212529;
		svg {
			path {
				fill: #212529;
			}
		}
	}
}
.clientSidebar_hide_btn {
	position: absolute;
	width: 32px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px 0 0 4px;
	left: calc(100% - 32px);
	top: calc(50% - 80px);
	cursor: pointer;
	color: rgba(255, 255, 255, 0.5);

	svg {
		path {
			fill: rgba(255, 255, 255, 0.5);
		}
	}

	&:hover {
		background: rgba(255, 255, 255, 0.1);
		svg {
			path {
				fill: #fff;
			}
		}
	}
}
.hidden {
	pointer-events: none;
	display: none;
}
