.displayContainer {
	height: 64px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 12px 24px;
	cursor: pointer;
	max-width: 260px;
	@media (max-width: 600px) {
		height: 40px;
		padding: 12px 12px;
		span {
			display: none;
		}
	}
	&:hover {
		background-color: #f5f5f5;
		span {
			color: #212529;
		}
	}
	span {
		font-size: 16px;
		line-height: 24px;
		font-weight: normal;

		letter-spacing: -0.04em;
		color: #6c757d;
		margin-right: 10px;
	}
}

.profileIcon {
	margin-right: 16px;
	@media (max-width: 600px) {
		transform: scale(0.6);
		margin-right: 2px;
	}
}
.dropdownitem {
	width: 220px;
	padding: 10px 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	transition: background-color 0.1s ease-in-out;
	&:hover {
		background-color: #f5f5f5;
	}
	a {
		display: flex;
		text-decoration: none;
		svg {
			width: 20px;
			height: 20px;
		}
	}
	span {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: #212529;
		text-decoration: none;
	}
	svg {
		margin-right: 20px;
		width: 20px;
		height: 20px;
	}
}
