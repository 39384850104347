.edit-vinstant-processing {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5rem;
	height: 100%;
}

.edit-vinstant-processing p {
	margin: 0;
}

.edit-vinstant-processing-spinner {
	color: #007bff;
}

.edit-vinstant-processing-wrapped {
	display: flex;
	justify-content: center;
	border: 1px dashed #ced4da;
	padding: 10px 16px 10px 10px;
	border-radius: 10px;
}
