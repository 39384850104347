.paginationContainer {
	min-height: 40px;
	overflow: hidden;
	border: 1px solid #ced4da;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	//width: 100%;
	margin: 19px 24px 20px 24px;
}
.select {
	max-width: 70px;
	border: none !important;
	height: 100%;
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
	min-width: 70px;
}
.group {
	color: #6c757d;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	white-space: nowrap;
	i {
		font-size: 22px;
		margin: auto;
	}
}
.withhover:hover {
	background-color: #f5f5f5;
	i {
		color: #212529;
	}
}
.separator {
	content: '';
	height: 100%;
	width: 1px;
	background-color: #ced4da;
}

/* And other times this */
@media (min-width: 1333px) and (max-width: 1295px) {
	.paginationContainer {
		transform: scale(0.8);
	}
}
