.content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.item {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.counts {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	svg {
		width: 16px;
		height: 16px;
	}
}
.countsText {
	display: flex;
	gap: 0.5rem;
	h6 {
		margin: 0;
		gap: 0.5rem;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.content {
		gap: 1rem;
	}

	.inputs {
		display: flex;
		flex-direction: column;
	}

	.countsText {
		display: flex;
		gap: 0.5rem;
		h6 {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.03em;
		}
	}
}
