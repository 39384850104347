.templateIframeContainer,
.templateIframe {
	width: 100%;
	height: 100%;
}

.templateIframe {
}
.templateIframe_mobile {
	width: 100vh;
}
