.rightFooterWrapper {
	padding: 1.5rem;
}
.rightFooter {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	button {
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: -0.05em;
		text-align: center;
	}
}
.rightFooterPrimaryWrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;

	p {
		margin: 0;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
	}
}
.rightFooterPrimary {
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.04em;
}

.addedList {
	display: flex;
	width: 100%;
	flex-direction: column;
	overflow-y: auto;
	flex-grow: 1;
	border-bottom: 1px solid #ced4da;
}

.addedItemWrapper {
	width: 100%;
	border-bottom: 1px solid #ced4da;
}
.addedItem {
	display: flex;
	gap: 16px;
	padding: 1.5rem 1.5rem;
}
.addedItemImage {
	width: 72px;
	height: 72px;
	border-radius: 4px;
	cursor: pointer;
}
.itemInfoWraper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 8px;
}
.itemInfo {
	display: flex;
	flex-direction: column;

	div {
		font-weight: 500;
		letter-spacing: -0.03em;
	}
	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.03em;
	}
}
.removeItemButton {
	&:hover {
		color: #dc3545;
	}
}

@media only screen and (max-width: 600px) {
	.rightFooterWrapper {
		padding: 1rem 0 0 0;
	}
	.rightFooter {
		button {
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.03em;
		}
	}
	.rightFooterPrimary {
		font-size: 16px;
		line-height: 24px;
	}
	.addedItem {
		padding: 1rem 1rem 0 1rem;
	}
	.addedItemWrapper {
		border: none;
	}
	.addedItemImage {
		width: 56px;
		height: 56px;
	}

	.itemInfo {
		div {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}
		span {
			font-size: 12px;
		}
	}
}
