.templatesModalBodyItem {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
}
.templatesModalBodyItem_mobile {
	flex-wrap: wrap;
}
.modalBody {
	padding: 0;
	width: 100%;
}
.modalBody_mobile {
	overflow-x: scroll;
}
