.rangeControl {
	display: flex;
	flex-direction: column;
	gap: 15px;

	p {
		margin: 0;
		letter-spacing: -0.03em;
	}
}
.rangeControl_slider {
	display: flex;
	align-items: center;
	gap: 16px;
	height: 2.5rem;
}
.rangeControl_value {
	width: 90px;
	min-width: 90px;
	height: 46px;
	max-width: 90px;
	border: 1px solid #ced4da;
	border-radius: 4px;
	p {
		padding: 0.5rem 1rem;
		margin: 0;
		font-family: Inter;
		font-size: 16px;
		line-height: 30px;
		letter-spacing: -0.04em;
		text-align: left;
	}
}

.leftPanelFooter {
	padding: 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	button {
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: -0.05em;
	}
}
.leftPanelFooterPrimary {
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.04em;

	p {
		margin: 0;
		color: inherit;
	}
}
.placementsList {
	display: flex;
	width: 100%;
	flex-direction: column;
	overflow-y: auto;
	flex-grow: 1;
	gap: 0.4rem;
	border-bottom: 1px solid #ced4da;
}
.placementItemWrapper {
	width: 100%;
	border-bottom: 1px solid #ced4da;
	cursor: pointer;
}
.placementItem {
	display: flex;
	flex-direction: column;
	padding: 0.5rem 1.5rem;
}
.placementItem_primary {
	margin: 0;
	font-weight: 400;
	letter-spacing: -0.03em;
}
.placementItem_secondary {
	color: #6c757d;

	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.03em;
}
@media only screen and (max-width: 600px) {
	.rangeControl {
		gap: 0.5rem;

		p {
			font-size: 12px;
			line-height: 20px;
		}
	}

	.rangeControl_slider {
		height: 2rem;
	}

	.rangeControl_value {
		height: 36px;
		width: 75px;
		min-width: 75px;

		p {
			padding: 6px;
			font-size: 16px;
			letter-spacing: -0.03em;
		}
	}

	.leftPanelFooter {
		gap: 12px;
		padding: 1rem;

		button {
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.04em;
		}
	}

	.leftPanelFooterPrimary {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.03em;
	}
	.placementItem_primary {
		letter-spacing: -0.05em;
	}
	.placementItem_secondary {
		line-height: 20px;
		letter-spacing: -0.05em;
	}
}
