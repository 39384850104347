.recommendationsContainer {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.recommendationsContent {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
//mobile view
@media only screen and (max-width: 600px) {
	.recommendationsContainer {
		gap: 1rem;
	}
}
