.notification {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 16px 40px 16px 0;
}

.notificationText {
  font-family: Inter,'sans-serif';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #212529;
    a {
      text-decoration: none;
      color: #007BFF;
    }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }
}
.notificationDate {
  margin-top: 8px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #6C757D;

}
.notificationIndicator {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: background-color 200ms ease-out;
  &:hover {
    background-color: #F5F5F5;
  }
  &::before {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    content: '';
    display: block;

  }
}
.notificationRead {
  &:hover {
    &::before {
      border: 1px solid #6C757D;
    }
  }
  &::before {
    border: 1px solid #CED4DA;

  }
}
.notificationNotRead {
  &::before {
    background-color: #007BFF;
  }
}
