.format,
.format_selected {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: -0.03em;
	text-align: left;
	width: 100%;
	display: flex;
	padding: 1.5rem;
	gap: 0.5rem;
	justify-content: flex-start;
	align-items: center;

	flex-direction: column;
	border-radius: 8px;
	cursor: pointer;

	.title {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
	p {
		margin: 0;
	}
}

.format {
	border: 1px solid #ced4da;
	.icon {
		fill: #212529;
	}
	.title {
		display: flex;
		flex-direction: column;
		gap: 4px;
		p {
			color: #6c757d;
		}
	}

	&:hover {
		background: rgba(0, 123, 255, 0.1);
		color: #007bff;
		border: 1px solid #007bff;

		.icon {
			fill: #007bff;
		}
		.title {
			p {
				color: #007bff;
			}
		}
	}
}
.format_selected {
	background: #007bff;
	border: 1px solid #007bff;
	color: #ffffff;
	.icon {
		background-color: none;
		fill: white;
	}
	.title {
		p {
			color: rgba(255, 255, 255, 0.5);
		}
	}

	&:hover {
		background: #006bdd;
	}
}
.format_disabled {
	opacity: 0.5;
	border: 1px solid #ced4da !important;
	&:hover {
		background: none;
		color: black;

		.icon {
			fill: #212529;
		}
		.title {
			p {
				color: #6c757d;
			}
		}
	}
}
.title {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.04em;

	color: inherit;

	p {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		letter-spacing: -0.03em;
	}
}
.icon {
	min-width: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	border-radius: 50%;
}
//mobile view
@media only screen and (max-width: 600px) {
	.title {
		p {
			text-align: start;
		}
		text-align: start;
		font-size: 14px;
	}
	.format,
	.format_selected {
		padding: 1rem;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		height: auto;
		gap: 1rem;
	}
	.icon {
		align-items: flex-start;
	}
}
