//first table style
.tableFrame {
	width: calc(100% - 80px);
	height: auto;
	left: 24px;
	right: 24px;
	top: 68px;
	bottom: 24px;
	background-color: white;
	// border-radius: 8px;
	margin-left: 40px;
	margin-right: 40px;
	margin-bottom: 15px;

	& > table > thead > tr > th {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		// line-height: 22px;
		/* identical to box height, or 157% */

		// letter-spacing: -0.03em;
	}
}

.scalable {
	cursor: pointer;
	&:hover {
		transform: scale(1.2);
	}
}

.headerCell {
	&.sortable {
		cursor: pointer;
	}
	&:hover .sort.sort {
		display: inline-block;
	}
}

.sort {
	display: none;
	margin-left: 10px;
	margin-right: 10px;
	align-self: center;

	&.active {
		display: inline-block;
	}
}

.pointer {
	cursor: pointer;
}

.upIcon {
	transform: rotate(180deg);
}

.cell {
	display: flex;
	align-items: center;
	cursor: pointer;
	overflow: visible;
	white-space: nowrap;
	height: 100%;
}

.iconButton {
	cursor: pointer;
	&:hover {
		color: #007bff;
	}
}

.regionCount {
	color: #6c757d;
	background: #f5f5f5;
	font-size: 12px;
	line-height: 12px;
	padding: 4px;
	border-radius: 4px;
}

//small modal style ---------------------------------------

.modalContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	width: 318px;
	// height: 120px;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	z-index: 0;
	box-shadow: 0px 4px 20px 0px #2125291a;
	border: 1px solid #ced4da;

	@media only screen and (min-width: 390px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 12px 0px;
		isolation: isolate;

		position: absolute;
		width: 320px;
		// height: 144px;
		left: 0px;
		bottom: 0px;

		background: #ffffff;
		border-radius: 8px;
	}
}

.modalContentOnDay {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	width: 318px;
	height: 40px;
	background: #ffffff;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}
}

.modalContentOnDayContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 16px;
	width: 318px;
	height: 40px;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	fill: #007bff;
}

.modalContentOnDayContentIcon {
	margin-top: -8px;
}

.modalContentOnDayContentext {
	width: 254px;
	height: 24px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	flex: none;
	order: 1;
	flex-grow: 1;
	color: #007bff;
	margin-bottom: 0;
}

.modalContentOnPeriod {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	width: 318px;
	height: 40px;
	background: #ffffff;
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
}

.modalContentOnPeriodContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 16px;
	width: 318px;
	height: 40px;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
}

.modalContentOnPeriodContentIcon {
	margin-top: -8px;
}

.modalContentOnPeriodContentext {
	width: 254px;
	height: 24px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	color: #212529;
	flex: none;
	order: 1;
	flex-grow: 1;
}

.modalContentOnDelete {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;

	width: 318px;
	height: 40px;

	background: #ffffff;

	flex: none;
	order: 3;
	align-self: stretch;
	flex-grow: 0;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}
}

.modalContentOnDeleteContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 16px;
	color: #dc3545;

	width: 318px;
	height: 40px;

	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
}

.modalContentOnDeleteContentIcon {
	margin-top: -8px;
}

.modalContentOnDeleteContentext {
	width: 254px;
	height: 24px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	margin: 0;

	color: #dc3545;

	flex: none;
	order: 1;
	flex-grow: 1;
}

//mobile
.mobileHeaderLine {
	// position: absolute;
	height: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	/* Global/Global/Line */

	border: 1px solid #ced4da;
}

.mobileCampaign {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 4px;
	isolation: isolate;

	width: 100%;
	height: 200px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	order: 3;
	flex-grow: 0;

	& > span {
		width: 100%;
		height: 0px;

		border: 1px solid #ced4da;

		/* Inside auto layout */
		order: 6;
		flex: none;
		align-self: stretch;
		flex-grow: 0;
		margin-top: 4px;
	}
}

.mobileHeaderFrame {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;

	width: 100%;
	height: 32px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	z-index: 0;
}

.mobileHeaderFrameText {
	width: 200px;
	height: 22px;

	/* Body/Paragraph 2 */

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	/* identical to box height, or 157% */

	letter-spacing: -0.03em;

	/* Text/Dark */

	color: #212529;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 1;
}

.mobileAddIconOneFrame {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 10px;
	gap: 10px;
	margin-top: -15px;
	width: 32px;
	height: 32px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}

.mobileAddIconTwoFrame {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 2px;
	gap: 10px;

	width: 27px;
	height: 16px;

	/* Text/White */

	// background: #FFFFFF;
	// /* Global/Global/Line */

	// border: 1px solid #CED4DA;
	// border-radius: 4px;

	// /* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.mobileADD {
}

.mobileLineOneFrame {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 100%;
	height: 16px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	z-index: 1;

	& > h1 {
		width: 100px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	& > h2 {
		width: 172px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 1;
	}
}

.mobileLineTwoFrame {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 16px;
	margin-top: 5px;
	width: 100%;
	height: 16px;

	/* Inside auto layout */

	flex: none;
	order: 2;
	align-self: stretch;
	flex-grow: 0;
	z-index: 2;

	& > h1 {
		width: 100px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	& > h2 {
		width: 80px;
		height: 16px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		flex-wrap: nowrap;
		letter-spacing: -0.03em;
		color: #212529;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: none;
		order: 0;
		flex-grow: 0;
	}
}

.mobileLineThreeFrame {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 288px;
	height: 16px;

	/* Inside auto layout */

	flex: none;
	order: 3;
	align-self: stretch;
	flex-grow: 0;
	z-index: 3;

	& > h1 {
		width: 100px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	& > h2 {
		width: 172px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 1;
	}
}

.mobileLineFourFrame {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 288px;
	height: 16px;

	/* Inside auto layout */

	flex: none;
	order: 4;
	align-self: stretch;
	flex-grow: 0;
	z-index: 4;

	& > h1 {
		width: 100px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	& > h2 {
		width: 172px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 1;
	}
}

.mobileLineFiveFrame {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;

	width: 288px;
	height: 16px;

	/* Inside auto layout */

	flex: none;
	order: 5;
	align-self: stretch;
	flex-grow: 0;
	z-index: 5;

	& > h1 {
		width: 100px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Global/Secondary */

		color: #6c757d;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	& > h2 {
		width: 172px;
		height: 16px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		letter-spacing: -0.03em;

		/* Text/Dark */

		color: #212529;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 1;
	}
}

.mobileFooter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 133px;

	width: 100%;
	height: 32px;

	/* Inside auto layout */

	flex: none;
	order: 6;
	align-self: stretch;
	flex-grow: 0;
	z-index: 6;
}

.mobileStateDiv {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 91px;
	height: 18px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.mobileMoreIconDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 8px;
	gap: 16px;

	width: 32px;
	height: 32px;

	/* Text/White */

	background: #ffffff;
	border-radius: 4px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}

.mobileSecondLine {
	position: absolute;
	height: 1px;
	left: 16px;
	right: 16px;
	bottom: 0px;

	border: 1px solid #ced4da;

	/* Inside auto layout */

	flex: none;
	order: 7;
	flex-grow: 0;
	z-index: 7;
}

//mobile modal

.mobileModal {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	padding: 12px 0px;
	isolation: isolate;
	z-index: 4;
	position: absolute;
	width: 100%;
	left: 0px;
	bottom: 0px;

	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
}

.mobileModalOnDay {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;

	width: 320px;
	height: 40px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	z-index: 0;
}

.mobileModalOnDayFrame {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;

	width: 288px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 1;

	& > span {
		width: 174px;
		height: 24px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		/* identical to box height, or 171% */

		display: flex;
		align-items: center;
		letter-spacing: -0.03em;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 0;
	}
}

.mobileModalOnPeriod {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;

	width: 320px;
	height: 40px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
	z-index: 1;
}

.mobileModalOnPeriodFrame {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;

	width: 288px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 1;

	& > span {
		width: 221px;
		height: 24px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		/* identical to box height, or 171% */

		display: flex;
		align-items: center;
		letter-spacing: -0.03em;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 0;
	}
}

.mobileModalOnDelete {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;

	width: 320px;
	height: 40px;

	background: #ffffff;

	/* Inside auto layout */

	flex: none;
	order: 2;
	flex-grow: 0;
	z-index: 2;
}

.mobileModalOnDeleteFrame {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;

	width: 288px;
	height: 24px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 1;

	& > span {
		width: 66px;
		height: 24px;

		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		/* identical to box height, or 171% */

		display: flex;
		align-items: center;
		letter-spacing: -0.03em;

		/* Global/Error */

		color: #dc3545;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 0;
	}
}

.Rectangle {
	position: absolute;
	width: 56px;
	height: 2px;
	left: calc(50% - 56px / 2);
	top: 4px;

	background: #ced4da;
	border-radius: 4px;

	/* Inside auto layout */

	flex: none;
	order: 3;
	flex-grow: 0;
	z-index: 3;
}

.expandedRow {
	display: flex;
	flex-direction: column;
	box-shadow: inset 0px 4px 8px rgba(33, 37, 41, 0.15);
}
.expandedRowShadowed {
	box-shadow: inset 0px 4px 8px rgba(33, 37, 41, 0.15),
		inset 0px -4px 8px rgba(33, 37, 41, 0.15);
}
.reportRow {
	div {
		h5 {
			font-size: 14px;
		}

		h6 {
			font-size: 14px;
		}
	}

	> div {
		button {
			font-size: 10px;
		}
	}
}

.mobileReport {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	div {
		gap: 8px;
	}
}
