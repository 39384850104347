.garandidCampaignItem {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding: 0;
}
.garandidCampaignItemHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1.5rem;
}
.garandidCampaignItemPlacement {
	display: flex;
	flex: 1 1;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}
.garandidCampaignPlacementInfo {
	display: flex;
	gap: 1rem;
	align-items: center;
	width: calc(50% - 64px);
}
.garantidItemMenuButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	padding: 4px 0;
	svg {
		width: 16px;
		height: 16px;
		color: #212529;
	}

	&:hover {
		background-color: #f5f5f5;
	}
}
.garantidItemMenu {
	border: 1px solid #ced4da;
	border-radius: 12px;
	box-shadow: 0px 4px 4px 0px #21252926;
	width: 280px;
}

.garantidmMenuItem {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	color: #6c757d;

	svg,
	i {
		display: flex;
		color: #6c757d;
		fill: #6c757d;
		width: 16px;
		height: 16px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: left;
		margin: 0;
	}
}
.placementAddress {
	max-width: 100%;
	h5 {
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
		margin: 0;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}

.stateWithCreative {
	display: flex;
	align-items: flex-start;
	gap: 40px;
}
.stateWithMessage {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 50%;

	P {
		margin: 0;
	}
}
.stateWithMessageStatus {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	p {
		margin: 0;
	}
}
.progressLineContainer {
	height: 0.25rem;
	border-radius: 0.5rem;
	background-color: #e9ecef;
	position: relative;
}
.progressLine {
	border-radius: 0.5rem;
	height: 0.25rem;
	position: absolute;
}
.uploadButton {
	width: 40px;
	height: 40px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 16px;
		height: 16px;
		color: #6c757d;
		fill: #6c757d;
	}

	&:hover {
		background-color: #f5f5f5;
		svg {
			fill: #212529;
		}
	}
}
.mediaPreviewExtendedButtons {
	display: flex;
	gap: 0.5rem;
}
.garandidCampaignItemPlacement_bold {
	display: flex;
	flex: 1 1;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;

	h5 {
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
	}
}
//mobile view
@media only screen and (max-width: 900px) {
	.garandidCampaignItem {
		gap: 1rem;
	}
	.garandidCampaignItemPlacement {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;

		h5 {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: left;

			text-overflow: unset;
			white-space: inherit;
			overflow: hidden;
		}
	}
	.garandidCampaignItemHeader {
		align-items: flex-start;
	}
	.garandidCampaignPlacementInfo {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}
	.stateWithMessage {
		width: 100%;
		gap: 0.75rem;
		P {
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			text-align: left;
		}
	}
	.stateWithCreative {
		flex-direction: column;
		gap: 1rem;
	}
	.mediaPreviewExtendedButtons {
		flex-direction: column;
	}
	.garandidCampaignItemPlacement_bold {
		flex-direction: column;
		align-items: flex-start;
		gap: 2px;

		h5 {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.03em;
		}
	}
}
@media only screen and (max-width: 600px) {
	.garantidmMenuItem {
		padding: 0.5rem 0;
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.03em;

		justify-content: space-between;
		color: #212529;

		svg,
		i {
			display: none;
		}
	}
	.garandidCampaignMenuItem_bordered {
		border-bottom: 1px solid #ced4da;
	}
	.garandidItemMenuMobile {
		padding: 0 1rem 0.5rem 1rem;
	}
	.garandidCampaignMenuItem_delete {
		background-color: #dc3545;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		border-radius: 12px;
	}
}
