.page {
	position: relative;
	height: 100vh;
	display: flex;
	//align-items: stretch;
	transition: margin-left 0.5s ease-in-out;
	flex-direction: column;
	flex-wrap: nowrap;
	@media (min-width: 1132px) {
		&_sideBarOpen {
			margin: 0 0 0 240px;
		}
	}
	&_wide {
		margin: 0 0 0 0;
	}
	&__body {
		flex: 1 0 auto;
		margin-top: 65px;
		display: flex;
	}
}
@media (max-width: 600px) {
	.page {
		&__body {
			margin-top: 40px;
		}
	}
}
@media (min-width: 1440px) {
	.page {
		&__body {
			display: flex;
			justify-content: center;
		}
		&__body_provider {
			display: block;
		}
	}
}
