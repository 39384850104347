.reportBody {
	padding: 0 40px 16px 40px;
}
.reportBody_mobile {
	padding: 0 16px 16px 16px;
}
.reportHeader {
	padding: 40px 40px 16px 40px;

	font-family: Inter;
	h1 {
		padding-bottom: 8px;
		border-bottom: 1px solid #ced4da;
		width: 100%;

		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.04em;
		text-align: left;
	}

	p {
		padding: 0;
		margin-top: 24px;
		margin-bottom: 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: left;
	}
}

.reportHeader_mobile {
	padding: 24px 16px;

	h1 {
		font-size: 28px;
		font-weight: 500;
		line-height: 32px;
	}
}

.reportCheckBox {
	input {
		cursor: pointer;
	}

	display: flex;
	gap: 8px;
	cursor: pointer;
	border-radius: 4px;
	padding: 8px 40px 8px 16px;
}

.reportCheckBox_withChildren {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.03em;
	text-align: left;

	input {
		margin-top: 5px;
		align-self: flex-start;
	}
}

.reportCheckBox:hover {
	background-color: #f5f5f5;
}

.selectedCount {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 16px;
}
.selectedCountView {
	display: flex;
	align-items: center;
	color: #6c757d;
	white-space: pre;
}
.selectedCountButton {
	color: #dc3545;
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: left;
}
