.table {
	width: 100%;
	margin-top: 20px;

	border-collapse: separate;
	border-spacing: 0;
	border-radius: 16px;
	border: 1px solid #ced4da;

	overflow: hidden;
	th,
	td {
		border-bottom: 1px solid #ced4da;
	}
}

.header {
	tr {
		background-color: #f5f5f5;
		th:not(:last-child) {
			border-right: 1px solid #ced4da;
		}
	}
}

.formatName {
	display: flex;

	button {
		border: none;
		p {
			display: none;
		}
	}

	&:hover {
		button {
			p {
				display: block;
			}
		}
	}
}

.headerCell {
	padding: 12px 16px;
}

.body {
	background-color: #ffffff;

	td {
		padding: 0;
	}

	tr {
		div {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				margin: 0;
			}
		}
		td:first-child {
			&:hover {
				background-color: #f5f5f5;
			}
		}
		td:not(:last-child) {
			div {
				border-right: 1px solid #ced4da;
			}
		}
	}

	//cтиль для всех кроме последних двух строк
	tr:not(:nth-last-child(-n + 2)) {
		td {
			span {
				display: block;
				height: 4px;
				width: 100%;
				border-top: 1px solid #ced4da;
				background-color: #e9ecef;
			}
		}
	}

	tr:last-child {
		background-color: #f5f5f5;
		td {
			border-bottom: none;
		}
	}
}

.bodyCell {
	padding: 20px 16px;
	p {
		margin: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
.detailButton {
	display: flex;
	align-items: center;
	gap: 4px;

	border-radius: 8px;
	background-color: #ffffff !important;
	p {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		color: #212529;
	}
	svg {
		width: 16px;
		height: 16px;
	}
}
