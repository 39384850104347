.container {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	max-width: calc(1100px + 40px + 40px);
	width: 100%;
	margin: 0 auto;
	padding: 40px;
	box-sizing: border-box;
	@media (max-width: 1180px) {
		//background: red;
	}
	//Mobile
	@media (max-width: 600px) {
		padding: 56px 16px 40px 16px;
	}
}

.campaignActionButton {
	width: 32px;
	height: 32px;
	border: none;
	border-radius: 4px;
	background: transparent;
	display: grid;
	place-items: center;
	padding: 0;
	&:hover {
		background: white !important;
		svg > path {
			fill: currentColor;
		}
		i {
			color: currentColor !important;
		}
	}
	@media (max-width: 600px) {
		background: white !important;
		svg > path {
			fill: currentColor;
		}
	}
}
.campaignMessage {
	display: flex;
	padding: 16px 24px;
	border-radius: 8px;
	align-items: flex-start;
	margin-bottom: 24px;
	@media (max-width: 600px) {
		font-size: 14px;
		margin-bottom: 16px;
	}
}
.campaignMessagePrimary {
	background: rgba(0, 123, 255, 0.1);
	color: #007bff;
}

.campaignDurationContainer {
	display: grid;
	grid-template-columns: 1.7fr 1fr 1fr;
	grid-gap: 16px;
	color: #212529 !important;
	margin-bottom: 40px;
	@media (max-width: 866px) {
		grid-template-columns: 1.7fr 1fr;
		grid-template-rows: 1fr 1fr;
	}

	> div {
		background-color: #ffffff;
		padding: 24px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		@media (max-width: 1020px) {
			padding: 16px;
		}
		@media (max-width: 970px) {
			padding: 12px;
			font-size: 14px;
		}
	}
}
.campaignDurationContainerMobile {
	background: #ffffff;
	border-radius: 8px;
	padding: 16px;
	margin-bottom: 8px;
	> div {
		display: flex;
		margin: 8px 0;
		font-size: 14px;
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

//Campaign Tabs
.campaignTabContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	margin-bottom: 40px;
	@media (max-width: 830px) {
		gap: 4px;
		margin-bottom: 16px;
		grid-template-columns: 1fr;
	}
}
.campaignTab {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 64px;
	border-radius: 8px;
	width: 100%;
	background-color: #ffffff;
	cursor: pointer;
	color: #212529;
	white-space: nowrap;
	font-size: 20px;
	padding: 0 6px;
	> svg {
		margin-right: 16px;
	}
	&:hover {
		background-color: rgba(0, 123, 255, 0.1);
		color: #007bff;
	}
	@media (max-width: 830px) {
		> svg {
			margin-right: 0;
			transform: scale(0.8);
		}
		flex-direction: column;
		height: 68px;
		font-size: 12px;
	}
}
.campaignTabDisabled {
	opacity: 0.5;
	cursor: default;
	&:hover {
		background-color: #ffffff;
		color: #212529;
	}
}
.campaignTabActive {
	background-color: #007bff;
	color: #ffffff;
	&:hover {
		background-color: #007bff;
		color: #ffffff;
	}
}
.campaignDurationContainer_extended {
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@media (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
}
.campaignTitle {
	display: flex;
	justify-content: space-between;
}
//mobile view
@media only screen and (max-width: 600px) {
	.campaignTitle {
		flex-direction: column;
		gap: 1rem;
	}
}
