.routeCard {
	border: none;
	padding: 64px;
	border-radius: 40px;
	width: 460px;
}

.backButton {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	border-radius: 1rem !important;

	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: -0.02em;

	i {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
	}
}

.submitButton {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	border-radius: 1rem !important;

	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: -0.02em;
}

//mobile view
@media only screen and (max-width: 600px) {
	.routeCard {
		padding: 40px 16px;
		width: 100%;
	}

	.backButton {
		i {
			font-size: 16px;
		}
	}
	.submitButton {
		border-radius: 0.75rem !important;
	}
}
