.districtBooking {
	border: 1px solid #ced4da;
	padding: 12px;
	border-radius: 4px;
	cursor: pointer;
	position: relative;
	flex-grow: 1;
	max-width: 50%;
	&:hover {
		background: rgba(0, 123, 255, 0.1);
	}
}
.districtBooking_mobile {
	max-width: none;
}

.addButton {
	width: 100%;
	display: flex;
	justify-content: center;
	button {
		display: flex;
		gap: 4px;
		align-items: center;
		fill: white;
		width: 100%;
		justify-content: space-around;
	}
}
.addButton_template {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	max-width: 50%;
	button {
		display: flex;
		gap: 4px;
		margin-right: 25px;
		align-items: center;
		fill: white;
	}
}
.massCoverage {
	height: 48px;
}

.outdoorHeader {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 0.5rem;
	h4 {
		margin: 0;
	}

	p {
		margin: 0;
	}
}
.primaryText {
	display: flex;
	justify-content: space-between;
}

//mobile view
@media only screen and (max-width: 600px) {
	.outdoorHeader {
		h4 {
			font-size: 20px;
			line-height: 28px;
		}
		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
}

.outdoorCard {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.selectorsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.outdoorCard {
		gap: 1rem;
	}
}
