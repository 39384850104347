.advPresentation {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.advHeader {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;

	h5 {
		margin: 0;

		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
	}
}
.advDescription {
	p {
		margin: 0;

		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.advPresentation {
		gap: 0.25rem;
	}
	.advHeader {
		gap: 1rem;
		align-items: flex-start;

		h5 {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}
	.advDescription {
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}
	}
}
