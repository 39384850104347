.advFormatStep {
	display: flex;
	flex-direction: column;
	gap: 24px;

	max-width: 800px;
	width: 800px;
	min-width: 700px;
}
//tablet
@media (max-width: 1300px) {
	.advFormatStep {
		width: 100%;
		max-width: none;
		min-width: auto;
	}
}
