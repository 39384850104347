.profileContainer {
  padding: 40px 40px 0 40px;
  @media (max-width:1132px) {
    padding: 24px 24px 0 24px;
  }
  h1 {
    padding: 0;
    margin: 0 0 24px;
    font-weight: 500;
  }
}

.profileSection {
  background-color: #FFFFFF;
  padding: 40px;
  margin-bottom: 16px;
  border-radius: 8px;
  @media (max-width:1132px) {
    padding: 24px;
  }
  h3 {
    margin: 0;
    padding: 0 0 16px;
  }
}

.personal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media (max-width:1132px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.personalInfo {
  padding-top: 24px;
  display: flex;
  flex-wrap: nowrap;
  @media (max-width:1132px) {
    margin-right: 0;
    //padding-bottom: 24px;
  }
  @media (max-width:600px) {
    flex-direction: column;
  }
}
.changePhone {
  padding-top: 24px;

  @media (max-width:1132px) {
    padding-top: 0;
    margin-left: 120px;

  }
  @media (max-width:600px) {
    margin-left: 0;
  }
}

.changePassword {
  padding-top: 24px;
  display: flex;
}

.organization {
  margin-top: 24px;
}

.popover {
  position: absolute;
  top: 40px;
  right: 10px;
  cursor: pointer;

  i {
    font-size: 24px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

