.buisnessContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.card {
	padding: 40px;
	border-radius: 24px !important;

	h2 {
		margin-bottom: 24px;
	}
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;

	button {
		border-radius: 16px;
	}
}

.limitCardContainer {
	display: flex;
	flex-direction: column;

	gap: 16px;

	padding: 0;
	margin: 0;
}
.listGroup {
	:first-child {
		padding-top: 0;
	}
	:last-child {
		padding-bottom: 0;
	}
}
.limitAmountItem {
	display: flex;
	justify-content: space-between;
	padding-left: 0;
	padding-right: 0;

	h6 {
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		margin: 0;
	}
	h5 {
		font-family: Inter;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
		margin: 0;
	}
}

.alert {
	display: flex;
	margin: 0;
	gap: 16px;

	border: none;
	background-color: #dc35451a !important;
	padding: 16px;
	border-radius: 16px;

	svg {
		fill: #dc3545;
	}

	p {
		color: #dc3545;
		margin: 0;

		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.card {
		padding: 32px 16px;
		border-radius: 20px !important;

		h2 {
			margin-bottom: 16px;

			font-size: 28px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: -0.02em;
		}
	}

	.buisnessContainer {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.alert {
		padding: 12px !important;
		gap: 8px;
		svg {
			width: 16px;
			height: 16px;
		}
		p {
			//styleName: Body/Paragraph 2;
			font-family: Inter;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
		}
	}
}
