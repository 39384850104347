.totalBudget {
	padding: 24px 40px;
	border-radius: 24px !important;

	h5 {
		margin: 0;

		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
	}
}
.totalBudgetContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

//mobile view
@media only screen and (max-width: 600px) {
	.totalBudget {
		padding: 1rem;
		border-radius: 20px !important;

		h5 {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			text-align: left;
		}
	}
}
