.CalendarMonth_table {
	border-collapse: separate !important;
}
.CalendarDay__selected_span {
	background: none;
	color: white;
	border: none;
	font-size: 12px;
}

.CalendarDay__selected {
	background: transparent;
	color: white;
	//font-size: 12px;
	border: none;
}
.CalendarDay__default {
	border: none;
}
.CalendarDay__default:hover {
	background: inherit;
	border: none;
	color: inherit;
}

.CalendarDay__selected_start {
	//border-radius: 8px 0 0 0;
}
.CalendarDay__selected_end {
	//border-radius: 0 0 8px 0;
}

.CalendarDay__selected:hover {
	background: transparent;
	color: white;
	border: none;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
	background: transparent;
	color: white;
	border: none;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
	background: transparent;
	border: none;
}

.CalendarMonth_caption {
	color: #212529;
	font-size: 16px;
	text-align: center;
	padding-top: 22px;
	padding-bottom: 47px;
	caption-side: initial;
	strong {
		font-weight: 500;
		font-size: 14px;
		text-transform: capitalize;
		line-height: 20px;
	}
}
.DayPicker__withBorder {
	box-shadow: 0 0 0 1px #ced4da;
	outline: none;
}
.DayPicker_weekHeader_li {
	small {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: rgba(33, 37, 41, 0.5);
	}
}
.DayPickerNavigation_button__disabled {
	display: none;
}
