.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 300px;
    margin-bottom: 30px;
  }
  button {
    margin-top: 40px;
  }
}