@import 'variables';

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local('Inter'),
		url('../assets/fonts/Inter-Black.ttf') format('truetype');
}

html {
	//overflow: hidden;
}

html,
body {
	--bs-body-bg: #f5f5f5;
	--bs-body-color: $dark01;
	--bs-body-font-family: 'Inter', sans-serif;
}
body {
	overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: -0.04em;
	color: #212529;
}

.ymaps-2-1-79-balloon__close + .ymaps-2-1-79-balloon__content {
	padding: 0;
	margin-right: 0 !important;
}
.btn-primary {
	background: #007bff;
}
.btn-primary:hover {
	background: #005ce1;
}
.btn-light {
	background-color: #f5f5f5;
	border-color: #f5f5f5;
}
.btn-link {
	text-decoration: none;
}
.alert-primary {
	background-color: #b8daff;
	color: #004085 !important;
	padding: 12px 28px 12px 20px;
}
.alert-secondary {
	color: #212529;
	letter-spacing: -0.04em !important;
	display: flex;
	align-items: center;
	background-color: #f5f5f5;
	border-color: #f5f5f5;
}
.modal-content {
	border: none;
	border-radius: 8px;
}
.modal-body {
	padding: 1.5rem;
}
.modal-header {
	align-items: flex-start;
}
.modal-header .btn-close {
	padding: 0 1.2rem 1.2rem;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
}
input::-ms-reveal {
	display: none;
}

.second-modal-backdrop {
	z-index: 1055;
}

.btn {
	border-radius: 8px;
}
.btn-group-disabled:disabled {
	color: #6c757d;
	background: #e9ecef;
	border-color: #e9ecef;
}
a {
	color: #007bff;
	text-decoration: none;
}

//scrollbar
*::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 10px;
}

*::-webkit-scrollbar {
	width: 5px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(33, 37, 41, 0.3);
	border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
	background-color: rgba(33, 37, 41, 0.5);
	border-radius: 10px;
}
.btn-outline-primary {
	color: $--bs-blue;
	border-color: $--bs-blue !important;
	*:hover {
		color: #fff !important;
		background-color: $--bs-blue !important;
		border-color: $--bs-blue !important;
	}
}

.btn-primary.disabled,
.btn-primary:disabled {
	background: #e9ecef;
	color: #6c757d;
	border: none;
	opacity: 1 !important;
}
.form-select:disabled {
	color: #6c757d;
}
.form-control:disabled {
	color: #6c757d;
}

.form-check-input:disabled {
	background-color: #e9ecef;
	background-image: none;
	border: 1px solid #ced4da;
}

hr {
	color: #ced4da;
	opacity: 1;
}

.form-label {
	color: #212529;
}

.modal-title {
	letter-spacing: -0.03em;
}

.modal-dialog {
	max-width: 540px;
}
.modal-lg {
	max-width: 730px !important;
}

.badge {
	padding: 3px 4px;
	font-weight: 500;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: -0.03em;
}
.card-header:first-child {
	/* Укажите ваши стили, чтобы переопределить существующие */
	border-top-left-radius: 1.5rem;
	border-top-right-radius: 1.5rem;
	/* Добавьте любые другие стили, которые хотите изменить или убрать */
}
@media (min-width: 576px) {
	.modal-dialog {
		//max-width: 540px;
	}
	.modal-fullscreen {
		max-width: 100vw;
	}
}
