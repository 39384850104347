.dropdown {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;

  &__menu {
    top: var(--dropdown-menu-position-top);
    right: var(--dropdown-menu-position-right);
    bottom: var(--dropdown-menu-position-bottom);
    left: var(--dropdown-menu-position-left);
    padding: var(--dropdown-menu-offset-top) var(--dropdown-menu-offset-right)
      var(--dropdown-menu-offset-bottom) var(--dropdown-menu-offset-left);
  }

  &__menu_absolute {
    position: absolute;
  }
}
