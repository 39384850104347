.container {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
}

.title {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	margin-left: 10px;
	/* identical to box height, or 150% */
	letter-spacing: -0.04em;
	flex: 1 1 auto;
	/* Text/Dark */
	color: #212529;
	margin-right: 10px;
}

.title:first-child {
}

.title:last-child {
	font-size: 12px;
	line-height: 20px;

	/* identical to box height, or 167% */
	letter-spacing: -0.03em;

	/* Text/Secondary */
	color: #6c757d;
}
