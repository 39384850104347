.stepCard {
	padding: 24px;
	border-radius: 8px;
	border: 1px solid #ced4da;
	background: #ffffff;
	color: #212529;
	display: flex;
	flex-wrap: nowrap;
	gap: 16px;
	cursor: pointer;
	&:hover {
		background: rgba(0, 123, 255, 0.1);
		border: 1px solid #007bff;
		h6,
		span {
			color: #007bff !important;
		}
		color: #007bff !important;
	}
}

.stepCardSelected {
	padding: 24px;
	border-radius: 8px;
	border: 1px solid #007bff;
	background: #007bff;
	color: #ffffff;
	display: flex;
	flex-wrap: nowrap;
	gap: 16px;
	cursor: pointer;
	h6,
	span {
		color: #ffffff !important;
	}
	&:hover {
		//background: #016ee3;
		//border: 1px solid #016ee3;
	}
	div > svg > path {
		fill: #ffffff;
	}
}
.amounts {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
}
.amount {
	display: flex;
	gap: 0.5rem;
	justify-content: space-between;
	max-width: 70%;
	h5 {
		margin: 0;
		//styleName: Heading/H5;
		font-family: Inter;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
		text-align: left;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.amount {
		max-width: 100%;
	}
}
