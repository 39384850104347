.bookingsUploadContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;

	& > div:not(:last-child) {
		padding-bottom: 1rem;
		border-bottom: 1px solid #ced4da;
	}
}
