.container {
	height: 100%;
	max-height: calc(100vh - 65px);
	background: #ffffff;
	display: flex;
	flex-direction: column;
}
.tabsWrapper {
	width: 100%;
	overflow-x: auto;
}
.tabsWrapper::-webkit-scrollbar {
	display: none;
}
.tabs {
	display: flex;
	padding: 0 40px;
	flex-wrap: nowrap;
	border-bottom: 1px solid #ced4da;

	> div {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 12px 7px;
		gap: 4px;
		white-space: nowrap;
		letter-spacing: -0.03em;
	}
}

.filterContainer {
	padding: 18px 40px;
	border-bottom: 1px solid #ced4da;

	&:first-child {
		flex-direction: flex-end;
	}
}

.bookingPlacementTypeImage {
	border-radius: 50%;
	display: grid;
	place-items: center;
	> svg > path {
		fill: #ffffff;
	}
}

.dropdown {
	position: relative;
}

.dropdown.open .dropdownMenu {
	display: block;
}

.dropdownToggle {
	cursor: pointer;
}

.dropdownMenu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 999;
	display: none;
	background-color: #fff;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdownMenu > div {
	margin-bottom: 8px;
}
