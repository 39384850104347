.yandexContainer {
	width: 100%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 16px;
}
.btnContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}
.blackBtn {
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 27px;
	border-radius: 8px;
	background-color: #212529;
	color: #ffffff;
	white-space: nowrap;
	@media (max-width: 600px) {
		font-size: 14px;
	}
}
.greyBtn {
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 27px;
	border-radius: 8px;
	background-color: #f5f5f5;
	color: #212529;
	white-space: nowrap;

	&:hover,
	&:focus-visible {
		background-color: #e9ecef;
	}
	@media (max-width: 600px) {
		font-size: 14px;
	}
}

.yandex_ad_container {
	display: flex;
	flex-direction: column;
	border: 1px solid #ced4da;
	border-radius: 8px;
}
.yandex_ad_header {
	padding: 10px 26px 10px 12px;
	display: flex;
	flex-wrap: nowrap;
	gap: 8px;
	align-items: center;
	width: 100%;
	img {
		width: 36px;
		height: 36px;
		border-radius: 8px;
	}
	> div {
		flex: 1 1 auto;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	div > {
		back span {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
		}
		p {
			margin: 0;
			font-family: 'Arial';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			color: #8b8b8b;
		}
	}
}
.yandex_ad_image {
	width: 100%;
}
.yandex_ad_content {
	padding: 12px 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	h1 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 8px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	p {
		margin-bottom: 0;
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		display: inline;
		align-items: center;
		color: rgba(0, 0, 0, 0.8);
	}
}

.yandex_search_container {
	border: 1px solid #ced4da;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 18px 15px;
	width: 100%;
	gap: 5px;
	> span {
	}
}
.yandex_search_title {
	display: flex;
	flex-wrap: nowrap;

	> span {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 20px;
		color: #6a1495;
		white-space: pre-wrap;
		flex: 1 1 auto;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	> svg {
		min-width: 3px;
		cursor: pointer;
	}
}

.yandex_search_text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.yandex_search_path {
	display: flex;
	max-width: 100%;
	gap: 4px;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	> img {
		width: 16px;
		height: 16px;
		border-radius: 4px;
	}
	> span {
		font-family: 'Arial';
		font-style: normal;
		font-size: 18px;
		line-height: 18px;
		/* identical to box height, or 100% */
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		color: #006000;
	}
}

.vk_container {
	border: 1px solid #ced4da;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 12px 0;
	width: 100%;
	max-width: 360px;
	& > h5 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		padding: 0 12px 1px 12px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	& > span {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 11px;
		line-height: 14px;
		color: #808c9b;
		padding: 0 12px 8px 12px;
	}
	& > p {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		padding: 0 12px 8px;
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	& > img {
		max-width: 100%;
	}
	& div > button {
		background-color: transparent;
		outline: none;
		border: 1px solid #007bff;
		border-radius: 8px;
		padding: 4px 8px;
		color: #007bff;
		margin-top: 12px;
		margin-right: 12px;
		text-transform: capitalize;
	}
}
