.custom-date-picker {
	position: relative;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	&__input {
		width: 165px;

		.invalid-feedback {
			position: absolute;
			top: 100%;
			font-size: 12px;
			line-height: 16px;
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 56px;
		width: 100%;
		height: 1px;
		background: #ced4da;
		z-index: 2;
	}

	&__button {
		position: absolute;
		top: 20px;
		cursor: pointer;
		z-index: 2;

		&_next {
			right: 40px;
			transform: rotate(180deg);
			top: 23px;
		}

		&_prev {
			left: 40px;
		}

		&_year-next {
			right: 16px;
			top: 23px;
			transform: rotate(180deg);
		}

		&_year-prev {
			left: 16px;
		}
	}

	.CalendarMonth_caption {
		color: #212529;
		font-size: 14px;
		line-height: 22px;
		padding-bottom: 74px;

		strong {
			text-transform: capitalize;
			font-weight: normal;
		}
	}

	.DayPicker_weekHeader {
		color: rgba(33, 37, 41, 0.5);
		top: 73px;
		text-transform: uppercase;
		small {
			font-size: 10px;
			line-height: 32px;
		}
	}

	.CalendarDay {
		border: none;
		border-radius: 4px;

		&:hover {
			background: #f4f4f4;
		}

		&__selected {
			background-color: transparent;
			color: #007bff;
			font-weight: bold;
			border: none;

			&:hover {
				background: #f4f4f4;
			}
		}
	}
}
@media (max-width: 600px) {
	.custom-date-picker {
		&__input {
			width: 100%;
		}
	}
}
.modal-date-picker-content {
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;
}
