.reportPlacement {
	margin-top: 16px;

	p {
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.03em;
		text-align: left;
		color: #6c757d;
		border-bottom: 1px solid #ced4da;
		padding-bottom: 4px;
		margin-bottom: 7px;
	}
}
.reportPlacement_mobile {
	margin-top: 8px;
}
