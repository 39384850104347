.infoContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
}

.lineLine {
	width: 360px;
	height: 0px;

	border: 1px solid #ced4da;

	/* Inside auto layout */

	flex: none;
	// order: 1;
	align-self: stretch;
	flex-grow: 0;
}

.imgFrame {
	height: 240px;
	background: #f5f5f5;
}

.infoimg {
	// position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
}

.items {
	padding: 24px;
	width: 100%;
}
