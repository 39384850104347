.file-input {
  width: 100%;
  position: relative;

  &__input {
    display: none;
  }

  &__label {
    background: #ffffff;
    border: 1px dashed #ced4da;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    justify-content: center;
  }

  &__file-preview {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__file-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  &__restrictions {
    color: #6c757d;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
  }

  &__blue {
    color: #007bff;
    cursor: pointer;
  }

  &__file-name {
    max-width: 100%;
    margin-bottom: 0;
    padding: 11px 16px;
    background: #ffffff;
    border: 1px solid #d6d8db;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__file-name-text {
    //max-width: calc(100% - 24px);
    text-overflow: ellipsis;
    overflow: hidden;
    //white-space: nowrap;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  &__file-delete {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &_error &__error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
    display: flex;
    justify-content: center;
  }

  &_error &__label {
    border-color: #dc3545;
  }

  &_error &__restrictions {
    display: none;
  }
}
