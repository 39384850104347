.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
}

.format {
	width: 100%;
	display: flex;
	align-items: center;
	height: 72px;
	flex-direction: row;
	padding-left: 24px;
	@media (max-width: 600px) {
		height: 56px;
		justify-content: flex-start;
		flex-direction: row;
		padding-left: 0;
	}
	border: 1px solid #ced4da;
	border-radius: 8px;
	cursor: pointer;
	color: #212529;
	&:hover {
		background: rgba(0, 123, 255, 0.1);
		color: #007bff;
		border: 1px solid #007bff;
		.icon svg path {
			fill: #007bff;
		}
	}
	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.04em;
		color: inherit;
	}
	.icon {
		margin-bottom: 5px;
		min-width: 24px;
		margin-right: 15px;

		svg path {
			fill: #212529;
		}
		@media (max-width: 600px) {
			margin-left: 15px;
			margin-bottom: 0;
		}
	}
}
.formatSelected {
	width: 100%;
	display: flex;
	align-items: center;
	height: 72px;
	flex-direction: row;
	padding-left: 24px;
	@media (max-width: 600px) {
		height: 56px;
		justify-content: flex-start;
		flex-direction: row;
		padding-left: 0;
	}
	background: #007bff;
	border: 1px solid #007bff;
	border-radius: 8px;
	cursor: pointer;
	color: #ffffff;
	&:hover {
		background: #006bdd;
	}
	.icon > svg > path {
		fill: #ffffff;
	}
	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.04em;
		color: inherit;
	}
	.icon {
		margin-bottom: 5px;
		min-width: 24px;
		margin-right: 15px;

		@media (max-width: 600px) {
			margin-left: 15px;
			margin-bottom: 0;
		}
	}
}
