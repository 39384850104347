.settingsContainer {
  padding: 40px 40px 0 40px;
  @media (max-width:1132px) {
    padding: 24px 24px 0 24px;
  }
  h1 {
    padding: 0;
    margin: 0 0 24px;
    font-weight: 500;
  }
}
.settingsSection {
  background-color: #FFFFFF;
  padding: 40px;
  margin-bottom: 16px;
  border-radius: 8px;
  @media (max-width:1132px) {
    padding: 24px;
  }
  h3 {
    margin: 0;
    padding: 0 0 16px;
  }
  hr {
    border-color: #CED4DA;
    opacity: 1;
    background-color:inherit;
  }
}


.inviteProvider {
  padding-top: 24px;
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

}


.currentInvites {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 24px;
  div.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    div:last-child {
      margin-right: 48px;
    }
  }
  div.datarow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 8px 0;
    a {
      text-decoration: none;
    }
    .deleteIcon {
      display: inline-block;
      padding: 7px 10px;
      color: var(--bs-secondary);
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #F5F5F5;
        color: #DC3545;
      }
    }
  }
}

.providerUsers {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 24px;
  div.rowTitle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 16px;
  }
  div.row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 16px 0;
    a {
      text-decoration: none;
    }
  }
}
.noProviderUsers {
  display: flex;
  align-items: center;
  h2 {
    margin-right: 16px;
  }
  h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: #6C757D;
  }
}