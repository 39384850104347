.indoorHeader {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 0.5rem;
	h4 {
		margin: 0;
	}

	p {
		margin: 0;
	}
}
.primaryText {
	display: flex;
	justify-content: space-between;
}

//mobile view
@media only screen and (max-width: 600px) {
	.indoorHeader {
		h4 {
			font-size: 20px;
			line-height: 28px;
		}
		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
}
