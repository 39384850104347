.footer  {
  width: 100%;
  padding: 22px 24px;
  border-top: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  color: #6c757d;
  @media (max-width: 770px) {
    justify-content: flex-start;
    flex-direction: column;
    padding: 24px 16px;
  }
  &_sidebarOpen {

  }
  &__links {
    font-size: 12px;
    line-height: 20px;

    @media (max-width: 770px) {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  &__link
   {
    text-decoration: none;
    color: #6c757d;
    margin-right: 24px;
    @media (max-width: 770px) {
      width: fit-content;
      margin-bottom: 8px;
    }
    &:hover {
      color: #6c757d;

    }
    &_blue {
      color: #007bff;
      &:hover {
        color: #007bff;

      }
    }
  }


  &__copyright {
    font-size: 12px;
    line-height: 20px;
    @media (max-width: 770px) {
      margin-top: 16px;
    }
  }
}
