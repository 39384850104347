.selectedAdvFormat {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

@media only screen and (max-width: 600px) {
	.selectedAdvFormat {
		gap: 1rem;
	}
}
