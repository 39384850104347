.container {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
}

.item {
	border: 1px solid #ced4da;
	border-radius: 8px;
	padding: 16px;
	margin-bottom: 16px;
}

.top {
	display: flex;
	justify-content: space-between;
}

.delete {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	cursor: pointer;
}

.title {
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	flex: 1 1 auto;
	/* Text/Dark */
	color: #212529;
	justify-content: space-around;
}

.rightContent {
	padding-left: 70px;
	position: relative;
}

.desc {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.cells {
	margin-top: 16px;
	margin-bottom: 8px;
}

.cell {
	position: relative;
	line-height: 24px;
	font-size: 16px;
	font-weight: 400;
}

.cellMargin {
	margin-left: 16px;
}

.cellBorder {
	position: absolute;
	right: 0;
	height: 28px;
	margin-top: 8px;
	border-right: 1px solid #ced4da;
}

.cellTitle {
	font-size: 12px;
	line-height: 20px;
	color: #6c757d;
}

.bottom {
	font-weight: 500;
	line-height: 24px;
	font-size: 16px;
	padding-top: 16px;
	border-top: 1px solid #ced4da;
	justify-content: space-between;
}
