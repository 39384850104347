.ymaps_my-hint {
	display: inline-block;
	background-color: black;
	position: relative;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	white-space: nowrap;
	letter-spacing: -0.05em;
	color: #ffffff;
	padding: 4px 8px;
	border-radius: 4px;
	top: -50px;
	left: -93px;
	&:after {
		width: 10px;
		height: 10px;
		content: '';
		background-color: black;
		position: absolute;
		top: calc(100% - 5px);
		left: calc(50% - 5px);
		transform: rotate(45deg);
	}
}
#ymaps_zoom-in,
#ymaps_zoom-out {
	width: 48px;
	height: 48px;
	background: #ffffff;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 8px;
	i {
		color: #212529;
		font-size: 30px;
	}
}

.ymaps_baloon-container {
	position: relative;
	.dot {
		content: '';
		background: #fff;
		position: absolute;
		width: 10px;
		height: 10px;
		top: 72px;
		left: 88px;
		border: 2px solid #ced4da;
		border-radius: 50%;
		box-shadow: 0px 4px 4px 0px rgba(33, 37, 41, 0.15);
	}
	&.selected {
		.dot {
			background: #007bff !important;
			border-color: #ffffff;
			top: -26px;
		}
	}
	&.focus {
		.dot {
			border-color: #007bff;
		}
	}
}

.placemark-hover {
	.ymaps_booking-baloon {
		transform: translateY(-8px);
		&.selected {
			transform: translateY(8px) !important;
		}
		max-width: fit-content;
		width: fit-content;
		box-shadow: 0px 8px 6px 0px rgba(33, 37, 41, 0.15);
		.data {
			.size {
				max-width: 177px;
			}
			.title {
				width: fit-content;
			}
		}
	}
	.dot {
		box-shadow: 0px 8px 6px 0px rgba(33, 37, 41, 0.15);
	}
}
.ymaps_booking-baloon {
	background-color: #fff;
	border: 2px solid #ced4da;
	padding: 8px 16px 8px 8px;
	border-radius: 4px;
	display: flex;
	flex-wrap: nowrap;
	min-width: 177px;
	max-width: 177px;
	min-height: 59px;
	max-height: 59px;
	box-shadow: 0px 4px 4px 0px rgba(33, 37, 41, 0.15);
	transition: transform 0.2s ease-in-out;
	position: relative;

	&::before {
		content: '';
		background: #ffffff;
		position: absolute;
		width: 16px;
		height: 16px;
		transform: rotate(225deg);
		top: 47px;
		left: 83px;
		border: 2px solid #ffffff;
		border-left-color: #ced4da;
		border-top-color: #ced4da;
	}

	.icon {
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f5f5f5;
		border-radius: 50%;
		margin-right: 8px;
		svg > path {
			fill: white;
		}
	}
	.data {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		.title {
			font-family: 'Inter', serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.04em;
			color: #212529;
			margin-bottom: 4px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 101px;
			width: 101px;
			margin-left: 0;
		}
		.size {
			font-family: 'Inter', serif;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: -0.03em;
			color: #007bff;
			z-index: 2;
			max-width: 101px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;
		}
	}
	&.unactive {
		.data {
			opacity: 0.5;
		}
		.icon svg > path {
			fill: #6c757d;
		}
	}
	&.selected {
		background-color: #007bff !important;
		border-color: #ffffff !important;
		.icon {
			background-color: #ffffff !important;
			svg > path {
				fill: #007bff !important;
			}
		}
		.data {
			.title {
				color: #ffffff;
			}
			.size {
				color: rgba(255, 255, 255, 0.6);
			}
		}
		&::before {
			background-color: #007bff !important;
			border-color: #007bff !important;
			border-left-color: #ffffff !important;
			border-top-color: #ffffff !important;
			transform: rotate(45deg);
			top: -8px;
		}
	}
	&.focus {
		border-color: #007bff;
		&::before {
			background-color: #ffffff;
			border-color: #ffffff;
			border-left-color: #007bff;
			border-top-color: #007bff;
		}
	}
}

#ymaps_big_adress_label {
	background-color: #6c757d;
	border: 2px solid #ffffff;
	padding: 8px 18px 8px 8px;
	border-radius: 4px;
	display: flex;
	flex-wrap: nowrap;
	min-width: 245px;
	max-width: 245px;
	min-height: 56px;
	height: auto;
	box-shadow: 0 4px 4px rgba(33, 37, 41, 0.15);
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	&::after {
		content: '';
		background: #6c757d;
		position: absolute;
		width: 10px;
		height: 10px;
		top: 75px;
		left: 115px;
		border: 2px solid #ffffff;
		border-radius: 50%;
	}
	&::before {
		content: '';
		background: #6c757d;
		position: absolute;
		width: 16px;
		height: 16px;
		transform: rotate(45deg);
		top: 50px;
		left: 112px;
		border: 2px solid #ffffff;
		border-left-color: #6c757d;
		border-top-color: #6c757d;
	}
	.icon {
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #007bff;
		border-radius: 50%;
		margin-right: 8px;
		svg > path {
			fill: #6c757d;
		}
	}
	.data {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		.title {
			font-family: 'Inter', serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.04em;
			color: #fff;
			margin-bottom: 4px;
			white-space: nowrap;
		}
		.size {
			font-family: 'Inter', serif;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: -0.03em;
			color: rgba(255, 255, 255, 0.6);
			opacity: 0.6;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 170px;
		}
	}
	&[data-hover='hover'] {
		min-width: max-content;
		.data .size {
			width: auto;
		}
	}
}
#ymaps_single_placement {
	svg {
		path {
			fill: #fff;
		}
	}
}
.ymaps-2-1-79-balloon__content {
	padding: 0 !important;
	padding-bottom: 10px !important;
}
.ymaps-2-1-79-balloon {
	border-radius: 8px !important;
}
.ymaps-2-1-79-balloon__layout {
	border-radius: 8px !important;
	overflow: hidden !important;
}
.ymaps-2-1-79-balloon__close {
	top: 4px;
	right: 10px;
}
#ymaps_cluster_baloon_content {
	.ymaps_cluster_baloon_content_container {
		display: flex;
		width: 290px;
		max-height: 350px;
		min-width: 290px;
		overflow: hidden;
		flex-direction: column;
		.ymaps_cluster_baloon_content_header {
			height: 48px;
			max-height: 48px;
			padding: 12px 16px;
			max-width: 100%;
			border-bottom: 1px solid #ced4da;

			.ymaps_cluster_baloon_content_title {
				padding-right: 30px;
				width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				font-size: 16px;
				font-family: 'Inter';
				font-weight: 500;
				line-height: 24px;
			}
		}
		.ymaps_cluster_baloon_content_places {
			height: 100%;
			max-height: 216px;
			overflow-y: auto;
		}
		.ymaps_cluster_baloon_content_place {
			padding: 16px;
			cursor: pointer;
			display: flex;
			flex-wrap: nowrap;
			&:hover {
				background-color: #f5f5f5;
				.ymaps_cluster_baloon_content_place_icon {
					background-color: #ffffff;
				}
			}
			&_icon {
				width: 40px;
				height: 40px;
				min-width: 40px;
				min-height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #f5f5f5;
				border-radius: 50%;
				margin-right: 8px;
				svg > path {
					fill: #212529;
				}
			}
			&_data {
				display: flex;
				flex-direction: column;
				&_title {
					font-family: 'Inter', serif;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: -0.04em;
					color: #212529;
					margin-bottom: 4px;
					white-space: nowrap;
				}
				&_size {
					font-family: 'Inter', serif;
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: -0.03em;
					color: #6c757d;
					opacity: 0.6;
					z-index: 2;
					white-space: nowrap;
				}
			}
		}
		.ymaps_cluster_baloon_content_place[data-state='unactive'] {
			opacity: 0.5 !important;
		}
		.ymaps_cluster_baloon_content_place[data-state='selected'] {
			.ymaps_cluster_baloon_content_place_icon {
				background: #007bff !important;
				svg > path {
					fill: #ffffff !important;
				}
			}
			.ymaps_cluster_baloon_content_place_data {
				&_title {
					color: #007bff !important;
				}
				&_size {
				}
			}
		}
	}
}
