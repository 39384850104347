.reportCard {
	padding: 1.5rem;
	border-radius: 24px;
	border: none;
	margin-bottom: 40px;
}
.reportContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.title {
	display: flex;
	align-items: center;
	gap: 1rem;

	h5 {
		margin: 0;

		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.02em;
	}
	svg {
		height: 20px;
		width: 16px;
	}
}

.actions {
	display: flex;
	align-items: center;
	gap: 1rem;

	button {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		border-radius: 16px;
		padding: 8px 16px;
		height: 40px;

		h6 {
			margin: 0;
		}

		svg {
			height: 16px;
			width: 16px;
		}
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.reportCard {
		padding: 16px;
		border-radius: 20px;
		margin-bottom: 24px;
	}

	.reportContent {
		flex-direction: column;
		gap: 12px;
	}
	.title {
		gap: 12px;
		align-items: flex-start;

		h5 {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}
		svg {
			width: 16px;
			height: 20px;
		}
	}
	.actions {
		flex-direction: column;
		gap: 8px;
		width: 100%;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.25rem;
			height: 32px;

			width: 100%;
			border-radius: 12px;

			svg {
				width: 16px;
				height: 16px;
			}

			h6 {
				margin: 0;

				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
			}
		}
	}
}
