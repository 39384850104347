.content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.inputs {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.viewInputs {
	width: 100%;
	display: flex;
	gap: 1rem;
}
.controlBudget {
	width: 50%;
}

.controlBudget {
	label {
		line-height: 24px;
		letter-spacing: -0.03em;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.content {
		gap: 1rem;
	}
	.inputs {
		gap: 1rem;
	}
	.controlBudget {
		width: 100%;
	}
}
