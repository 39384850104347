.container {
	width: 100%;
	height: 100%;
	position: relative;
	transition: padding-left 0.4s linear;
	overflow: hidden;
	padding-left: 408px;

	@media screen and (max-width: 1132px) {
		padding-left: 350px;
	}
}

.leftPanel {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 408px;
	transition: transform 0.4s ease;
	background-color: #ffffff;
	z-index: 10;

	@media screen and (max-width: 1132px) {
		width: 350px;
	}

	.image {
		width: 100%;
		height: 250px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
		margin-top: 24px;
		margin-bottom: 24px;

		img {
			max-width: none;
			min-width: auto;
			min-height: auto;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.info {
		padding: 0 24px;
		font-size: 14px !important;

		@media screen and (max-width: 1132px) {
			padding: 0 12px;
		}
	}
}

.leftPanelHide {
	transform: translateX(-100%);
}

.leftPanelShow {
	transform: translateX(0);
}

.hideBtn {
	width: 48px;
	height: 48px;
	background-color: #ffffff;
	border-radius: 50%;
	display: grid;
	place-items: center;
	position: absolute;
	top: calc(24px);
	right: -72px;
	cursor: pointer;

	i {
		font-size: 22px;
	}
}

.filterBookings {
	width: 1032px;
	height: 72px;
	left: 408px;
	top: 64px;

	/* Text/White */

	background: #ffffff;
}

.filterSearch {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;

	position: absolute;
	width: 400px;
	height: 36px;
	left: 40px;
	top: 18px;
}

.modalComponentTabs {
	position: absolute;
	width: 408px;
	height: 48px;
	left: 0px;
	top: 64px;
	background: #ffffff;
}

.modalComponentRight {
	position: absolute;
	width: 48px;
	right: -48px;
	// top: 48px;
	bottom: 86px;

	border: 1px solid #ced4da;
	transform: rotate(-90deg);
}

.buttonDiv {
	position: absolute;
	width: 16px;
	height: 16px;
	right: 16px;
	bottom: 75px;
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
}

.toggleButton {
	position: absolute;
	left: 17.88%;
	right: 17.89%;
	top: 37.5%;
	bottom: 26.22%;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: gray transparent transparent transparent;
}

.tabsFrame {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;
	position: absolute;
	width: 47px;
	height: 24px;
	left: 16px;
	top: -43px;

	& > h1 {
		width: 28px;
		height: 24px;

		/* Body/Paragraph 1 */

		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */

		letter-spacing: -0.03em;

		/* Global/Primary */

		color: #007bff;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
		bottom: 2px;
	}
}

.tabsNameLine {
	position: absolute;
	height: 0px;
	left: 0px;
	right: 0px;
	bottom: 60px;
	/* Global/Global/Line */
	width: 432px;
	border: 1px solid #ced4da;
}

.listHeaderBlock {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	gap: 8px;
	isolation: isolate;
	width: 469px;
	margin-left: -63px;
	height: 48px;
	background: #ffffff;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin-top: -7px;
	border-right: 1px solid #ced4da;
	border-bottom: 1px solid #ced4da;
}

.statusLine {
	position: sticky;
	width: 55px;
	right: -58px;
	top: 48px;
	bottom: 2px;
	border: 1px solid #ced4da;
	transform: rotate(-90deg);
	flex: none;
	order: 3;
	flex-grow: 0;
	z-index: 3;
	margin-right: -70px;
}

.statusDropLine {
	position: absolute;
	height: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	border: 1px solid #ced4da;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	z-index: 0;
}

.tabsLineTwo {
	position: absolute;
	left: 408px;
	right: 0px;
	transform: rotate(-90deg);
	width: 48px;
	border: 1px solid #ced4da;
}

.componentBookingsCards {
	position: absolute;
	width: 408px;
	left: 0px;
	top: 101px;
	bottom: 0px;
	overflow: auto;
}

.componentBookingsCardsLine {
	position: absolute;
	width: 864px;
	right: -604px;
	top: 864px;
	bottom: 0px;
	margin-top: 100px;
	transform: rotate(-90deg);
}

.modalFiltersContainer {
	position: absolute;
	left: 408px;
	top: 64px;
	right: 0;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #ced4da;
	border-left: 1px solid #ced4da;
	z-index: 1;

	/* Text/White */

	background: #ffffff;
}

.bookingsTabsContainer {
	// position: absolute;
	width: 408px;
	height: 48px;
	left: 0px;
	top: 60px;

	/* Text/White */

	background: #ffffff;
	margin-left: 5px;
}

.dropdownContainerLine {
	position: absolute;
	height: 0px;
	width: 100vw;
	left: 0px;
	right: 0px;
	/* bottom: 0px; */
	top: 105px;
	border: 1px solid #ced4da;
}

.dropdownContainerLineright {
	position: relative;
	width: 48px;
	right: -382px;
	top: 23px;
	bottom: 0px;
	border: 1px solid #ced4da;
	transform: rotate(-90deg);
}

.filterContainer {
	width: 100%;
	padding: 0px;
}

.filtersLine {
	position: absolute;
	height: 0px;
	left: 0px;
	right: 0px;
	bottom: -21px;
	width: 100vw;
	/* Global/Global/Line */

	border: 1px solid #ced4da;
}

.searcContainer {
	position: absolute;
	padding: 0px;
	width: 400px;
	height: 36px;
}

.containerdrop {
	position: relative;
	z-index: 999;
	background-color: white;
	width: 401px;
	border-bottom: 1px solid #ced4da;
	border-right: 1px solid #ced4da;
}

.dropdownHeader {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.arrowUp {
	transform: rotate(180deg);
	transition: transform 0.2s ease-in-out;
	margin-left: 320px;
}

.arrowDown {
	transition: transform 0.2s ease-in-out;
	margin-left: 320px;
}

.dropdownContent {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	padding: 10px;
	z-index: 1;
}
