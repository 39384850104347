.reportCard {
	width: 100%;
	padding: 1.5rem;
	border-radius: 1rem;
	overflow-x: auto;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		border-radius: 12px;
		height: 40px;
	}
	h3,
	h6 {
		margin: 0;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.header {
		flex-direction: column;
		gap: 12px;

		button {
			width: 100%;
			justify-content: center;
		}
	}
}
