.districtBookingEditContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.districtBookingEdit {
	display: flex;

	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	border-bottom: 1px solid #ced4da;
}
.districtBookingFooter {
	display: flex;
	gap: 1rem;
	padding: 1rem;
	align-items: center;
	justify-content: flex-end;
}

.editCard {
	display: flex;
	flex-direction: column;
	border-radius: 1.5rem;
}
.noGap {
	gap: 0;
}
.editCardHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;

	border: none;
	border-radius: 1.5rem 1.5rem;
	background-color: inherit;
	padding: 1rem;
}
.editCardHeader:first-child {
	border-bottom-left-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
}
//mobile view
@media only screen and (max-width: 900px) {
	.districtBookingEditContainer {
		height: 100%;
		max-height: 100%;
	}
	.districtBookingEdit {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		overflow: scroll;
	}
}
