.inputAlert {
	border-radius: 1rem;
}
.alertList {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.alertItem {
	display: flex;
	gap: 1rem;
}

//mobile view
@media only screen and (max-width: 600px) {
	.alertItem {
		justify-content: space-between;
	}
}
