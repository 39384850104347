.sheet {
	position: fixed;
	width: 100vw;
	background: white;
	border-radius: 8px 8px 0 0;
	display: flex;
	flex-direction: column;
	z-index: 1100;
}
.sheet::before {
	content: '';
	position: absolute;
	display: block;
	width: 56px;
	height: 2px;
	left: calc(50% - 56px / 2);
	top: 4px;
	background: #ced4da;
	border-radius: 4px;
}
.sheet::after {
	position: absolute;
	content: '';
	left: 0;
	top: 99%;
	width: 100vw;
	height: 100vh;
	background: white;
	z-index: 1100;
}
.touch {
	width: 100%;
	min-height: 15px;
	max-height: 15px;
	position: absolute;
	background: transparent;
	z-index: 10;
}
.children {
	width: 100%;
	overflow-y: auto;
	height: auto;
	flex-grow: 1;
}
.footer {
	width: 100%;
	max-height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}
.close {
	position: absolute;
	width: 24px;
	height: 24px;
	right: 16px;
	top: 20px;
	background: #f5f5f5;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}
