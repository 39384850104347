.autofillNone {
	&:-webkit-autofill:focus {
		box-shadow: 0 0 0 30px white inset !important;
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-webkit-text-fill-color: #000 !important;
	}

	&:-webkit-autofill {
		box-shadow: 0 0 0 30px white inset !important;
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-webkit-text-fill-color: #000 !important;
	}
}

.formContent {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.inputs {
	display: flex;
	flex-direction: column;
	gap: 24px;

	input {
		height: 48px;
		border-radius: 16px;
		border-color: #ced4da !important;
		box-shadow: none !important;
	}
}

.confirmationHelper {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin: 0;
}

.timerHelper {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #6c757d;
}

.resendCodeVariants {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;

	button {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #007bff;
		cursor: pointer;
	}
}

.newPasswordHelperText {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #6c757d;
}
