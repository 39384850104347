.notificationsTable {
	border-collapse: collapse;
	width: 100%;

	tr {
		&:hover {
			background-color: #f5f5f5;
		}
	}

	th,
	td {
		border: 1px solid #ddd;
		padding: 14px 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: left;
		max-height: 96px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	th {
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
	}
	td {
		cursor: pointer;
	}
}
.actionsCell {
	display: flex;
	height: 100%;
	text-align: none;
	div {
		padding: 0 14px;
		height: 100%;
		border-right: 1px solid #ced4da;
	}
}
.selectedRow {
	background-color: #f5f5f5;
}

.edit:hover {
	i {
		color: #007bff;
	}
}
.error:hover {
	i {
		color: #dc3545;
	}
}
.edit,
.error {
	cursor: pointer;
}
