.item {
	padding: 8px 0;
	display: flex;
	width: 100%;
	justify-content: space-between;
	border-bottom: 1px solid #ced4da;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;

	&.noBorder {
		border-bottom: 0;
	}

	&.size-16 {
		font-size: 16px;
	}
}

.left {
	color: #6c757d;
}

.right {
	color: #212529;
	text-align: right;
}
