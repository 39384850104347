.tabs {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #6c757d;
	letter-spacing: -0.05em;
	padding: 0 40px;
	border-bottom: 1px solid #ced4da;
	background-color: #fff;
}

.tab {
	padding: 12px 16px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	span {
		height: 18px;
		border-radius: 4px;
		padding: 3px 4px 3px 4px;
		margin-left: 4px;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.05em;
	}
}
.activeTab {
	color: #007bff;
	border-bottom: 2px solid #007bff;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ced4da;
	padding: 18px 40px;
	background-color: #fff;
	h1 {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 40px;
		line-height: 48px;
		/* identical to box height, or 120% */
		margin: 0;
		padding: 0;
		letter-spacing: -0.05em;
	}
	button {
		height: 36px;
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.04em;
	}
}
.header_mobile {
	padding: 12px 16px;
	width: 100%;
}
