.registration {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.registrationHeader {
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		font-family: Inter;
		font-size: 28px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.02em;
		margin: 0;
	}
}
.registrationFooter {
	display: flex;
	flex-direction: column;
	gap: 40px;
}
.footerLink {
	display: flex;
	justify-content: center;
	gap: 4px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;

	p {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #949494;
		margin: 0;
	}
	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #007bff;
		cursor: pointer;
	}
}

.registration {
	display: flex;
	flex-direction: column;
	gap: 40px;
} //mobile view
@media only screen and (max-width: 600px) {
	.registration {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
}
