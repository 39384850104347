.recPresentation {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.recHeader {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;

	h4 {
		margin: 0;

		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.02em;
	}
}
.recDescription {
	p {
		margin: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.recPresentation {
		gap: 0.25rem;
	}
	.recHeader {
		display: flex;

		justify-content: space-between;
		align-items: flex-start;

		h4 {
			font-size: 20px;
			font-weight: 500;
			line-height: 28px;
		}
	}
	.recDescription {
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}
	}
}
