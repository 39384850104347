@import 'variables';

.auth-page {
	letter-spacing: -0.05rem;
	color: #212529;
	height: 100vh;

	&__mainContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1 1;
	}

	&__cardContainer {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	&__logo {
		display: flex;
		justify-content: center;
	}

	&__body {
		max-width: 504px;
	}
	&__footer {
		justify-self: flex-end;

		display: flex;
		gap: 8px;
		justify-content: center;
		align-items: center;
		padding: 24px 0 24px 0;

		p,
		a {
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			margin: 0;
			color: #6c757d;
		}

		a {
			&:hover {
				cursor: pointer;
				color: #007bff;
			}
		}
	}

	&__header {
		text-align: center;
		margin-bottom: 40px;
		font-weight: 500;
	}

	&__input {
		position: relative;
	}

	&__password {
		position: relative;
	}

	&__popover {
		font-size: 24px;
		position: absolute;
		bottom: 14px;
		right: 16px;
		width: 24px;
		height: 24px;
		line-height: 24px;
		cursor: pointer;
		z-index: 3;
	}

	&__control {
		margin: 0;
		input {
			border-radius: 1rem;
		}
	}

	&__submit-button {
		width: 100%;
		border-radius: 1rem !important;
		margin-bottom: 16px;
		margin-top: 16px;
	}

	&__button-icon {
		margin-right: 4px;
	}

	&__text {
		text-align: center;
		color: $gray02;
	}

	&__link {
		text-decoration: none !important;
		cursor: pointer;
		color: $primary01;
	}

	&__message-text {
		text-align: center;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 40px;
	}

	&__checkbox {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
	}

	&__checkbox-input {
		margin-top: 0;
	}

	&__checkbox-label {
		font-size: 16px;
		line-height: 24px;
		margin-left: 8px;
		color: #212529;
	}

	&__button-link {
		width: 100%;
		text-decoration: none;
	}
}

input[type='password'] {
	//font: small-caption;
	font-size: 20px;
}
//mobile view
@media only screen and (max-width: 600px) {
	.auth-page {
		padding-top: 1rem;
		height: calc(var(--vh, 1vh) * 100);

		&__cardContainer {
			display: flex;
			flex-direction: column;
			gap: 24px;
			width: 100%;
		}
		&__mainContent {
			width: 100%;
		}
		&__body {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-bottom: 1rem;
		}
		&__logo {
		}
		&__control {
			margin: 0;
			input {
				border-radius: 0.75rem;
			}
		}
		&__submit-button {
			border-radius: 0.75rem !important;
		}
	}
}
