.massCoverageItemsList {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
.addCityButton {
	display: flex;
	width: 100%;
	justify-content: center;

	font-size: 16px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: -0.05em;
}
.massCoverageStatistic {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
//mobile view
@media only screen and (max-width: 600px) {
	.massCoverageItemsList {
		gap: 1rem;
	}
	.addCityButton {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.03em;
	}
	.massCoverageStatistic {
		font-size: 14px;
		line-height: 22px;
	}
}
