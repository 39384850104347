.contentLayout {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex: 1 1 0;
	overflow: hidden;
	> div:first-of-type {
		border-right: 1px solid #ced4da;
	}
	> div {
		width: 50%;
		display: flex;
		flex-direction: column;
	}
}
.contentLayout_mobile {
	> div {
		width: 100%;
	}
	> div:first-of-type {
		border: none;
	}
}
