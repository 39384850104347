.container {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  header {
    width: 100%;
    height: 48px;
    @media (max-width: 600px) {
      height: 40px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    border-bottom:1px solid #CED4DA;
    background-color: #FFFFFF;
    z-index: 2;
    .goBack {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 13px;
      position: absolute;
      top: 0;
      left: 0;
      i {
        color: #6C757D;
        font-size: 24px;
        @media (max-width: 600px) {
          font-size: 20px;
        }
      }
    }
    span {
      font-size: 14px;
      line-height: 20px;
      @media (max-width: 600px) {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  section {
    height: 100%;
    padding: 48px 24px 0 24px;
    @media (max-width: 600px) {
      padding: 40px 16px 0 16px;
    }
  }
}
.readAll {
  display: flex;
  justify-content: center;
  padding: 22px 0;
  @media (max-width: 600px) {
    padding: 20px 0;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}