.navigationBreadcrumb {
	margin: 0;

	a {
		display: flex;
		align-items: center;
		gap: 1rem;
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;

			i {
				color: #6c757d;
				font-size: 1rem;
			}
		}

		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #6c757d;
			margin: 0;
		}

		&:hover {
			span {
				i {
					color: #007bff;
				}
			}

			p {
				color: #007bff;
			}
		}
	}
}
