.disclaimer {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;
}

.progressBarContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.progressBar {
	height: 4px;
}
.progressBarText {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #007bff;
	margin: 0;
}

.alert {
	background-color: #007bff1a;
	border-radius: 16px;
	display: flex;
	gap: 16px;
	padding: 16px;

	svg {
		fill: #007bff;
		width: 20px;
		height: 20px;
	}

	p {
		margin: 0;

		font-family: Inter;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #007bff;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.disclaimer {
		gap: 16px;
		margin-bottom: 16px;
	}

	.progressBarText {
		font-family: Inter;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: -0.03em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
	}
	.alert {
		gap: 12px;
		padding: 12px;
		border-radius: 12px;

		svg {
			width: 16px;
			height: 16px;
		}
		p {
			//styleName: Body/Paragraph 2;
			font-family: Inter;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
		}
	}
}
