.appLinksContainer {
	margin-top: 24px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.topLinks,
.bottomLinks {
	display: flex;
	flex-direction: column;
}

.topLinks {
	padding: 0 8px;
}
.bottomLinks {
	padding: 16px 8px;
}

.modalActivator {
	display: flex;
	gap: 16px;
	padding: 12px 14px;

	box-shadow: none !important;

	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.04em;
	color: rgba(255, 255, 255, 0.5);
	fill: rgba(255, 255, 255, 0.5);

	&:hover {
		background: rgba(255, 255, 255, 0.1);
		color: #fff;
		fill: #fff;
	}
}

.link {
	text-decoration: none;
	display: flex;
	border-radius: 8px;
	justify-content: space-between;
	align-items: center;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.04em;
	padding: 12px 14px;
	color: rgba(255, 255, 255, 0.5);
	fill: rgba(255, 255, 255, 0.5);
	&:hover {
		background: rgba(255, 255, 255, 0.1);
		color: #fff;
		fill: #fff;
	}
	span {
		height: fit-content;
		background-color: rgba(255, 255, 255, 0.1) !important;
		color: rgba(255, 255, 255, 0.5) !important;
	}
}

.active {
	background: rgba(255, 255, 255, 0.1);
	color: #fff;
	fill: #fff;
	span {
		background-color: #007bff !important;
		color: rgba(255, 255, 255, 1) !important;
	}
}
