.editableCell {
	height: 48px;
	max-height: 48px;
	padding: 0 !important;
}
.editableCellContent {
	height: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.editableCellView {
	display: flex;
	justify-content: space-between;
	align-self: center;
	height: 100%;
	width: 100%;
	padding: 8px 16px;
	cursor: pointer;
	p {
		margin: 0;

		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
	button {
		visibility: hidden;
	}

	&:hover {
		button {
			visibility: visible;
		}
	}
}
.applyAllButton {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;

	width: 32px;
	height: 32px;

	svg {
		width: 16px;
		height: 16px;
		fill: #212529;
	}
}
