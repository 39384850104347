.garantidMediaFormatHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	border-radius: 1.5rem 1.5rem 0 0;
	background-color: inherit;
	padding: 0;
	h5 {
		margin: 0;
	}
}
.garantidMediaFormatHeader_placementsState {
	display: flex;
	width: 100%;

	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}
.mediaFormatStatistics {
	display: flex;
	align-items: center;
	gap: 1rem;

	p {
		margin: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}

//mobile view
@media only screen and (max-width: 900px) {
	.garantidMediaFormatHeader {
		align-items: flex-start;
	}
	.garantidMediaFormatHeader_placementsState {
		flex-direction: column;
		align-items: flex-start;
		gap: 2px;
		h5 {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}
	.mediaFormatStatistics {
		flex-direction: column;
		gap: 2px;
		align-items: flex-start;

		P {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: right;
		}
	}
}
