.backToSelectorsCard {
	padding: 24px 40px;
	border-radius: 24px !important;

	cursor: pointer;
}
.backToSelectorsCardContent {
	display: flex;
	align-items: center;
	gap: 1rem;

	p {
		margin: 0;

		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #6c757d;
	}

	svg {
		width: 16px;
		height: 16px;
		fill: #6c757d;
	}
}

@media only screen and (max-width: 600px) {
	.backToSelectorsCard {
		padding: 16px;
	}
	.backToSelectorsCardContent {
		gap: 0.5rem;

		p {
			font-size: 12px;
			line-height: 18px;
		}
	}
}
