.inputExtraButtons {
	display: flex;
	align-items: center;
	justify-self: flex-end;
	gap: 0.25rem;
}

//mobile view
@media only screen and (max-width: 600px) {
	.inputExtraButtons {
		flex-direction: column;
	}
}
