.container {
  position: fixed;
  width: 640px;
  height: 700px;
  overflow: hidden;
  border: 1px solid #CED4DA;
  box-shadow: 0 4px 20px rgba(33, 37, 41, 0.1);
  border-radius: 8px;
  background-color: #FFFFFF;
  right: 16px;
  top: 79px;
  z-index: 1000;

  header {
    width: 100%;
    height: 64px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #CED4DA;
    position: static;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px 0 16px;
    h5 {
      margin: 0;
      font-size: 20px;
      line-height: 28px;
    }
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.04em;
    }
  }

  section {
    height: calc(100% - 64px);
  }
}