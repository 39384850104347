.bookingDetailsImage {
	overflow: hidden;
	border-radius: 50%;
	max-width: 56px;
	min-width: 56px;
	max-height: 56px;
	min-height: 56px;
	display: grid;
	place-items: center;
	svg {
		scale: 1.4;
	}
	svg > path {
		fill: #ffffff;
	}
}

.bookingButtonsContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;

	&.vertical {
		background: white;
		flex-direction: column;
		overflow: hidden;
		border: 1px solid #ced4da;
		border-radius: 4px;
		box-shadow: 0px 4px 20px 0px #2125291a;
		padding: 8px 0;

		.bookingButton {
			flex-direction: row;
			justify-content: flex-start;
			padding: 8px 20px;
			gap: 8px;
		}
	}
}

.bookingButton {
	border: none;
	outline: none;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	&:hover {
		background-color: #f5f5f5;
	}
}
