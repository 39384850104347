.image_container {
	@media (max-width: 600px) {
		margin: 0px 6px 6px 0px;
		max-width: 160px;
		max-height: 90px;
	}
	@media (max-width: 400px) {
		max-width: 100%;
		width: 100%;
		height: 200px;
		max-height: 200px;
	}
	margin: 0px 1.5rem 1.5rem 0px;
	width: 160px;
	height: 90px;
	position: relative;
	box-sizing: border-box;
	border: 1px solid #ced4da;
	border-radius: 8px;
}

.image_item {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 8px;
}

.image_item_hovered {
	filter: brightness(0.7);
	cursor: zoom-in;
}

.template_button {
	position: absolute;
	bottom: 3%;
	left: 5%; /* Отступ слева */
	right: 5%; /* Отступ справа */
	margin: 0 auto;
}
.template_button_unavailable {
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	bottom: 0;
	color: white;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;
	cursor: 'auto';

	&:disabled {
		color: 'grey';
	}
}
.image_item_zoomed {
	position: absolute;
	transform: scale(1.5);
	transition: all ease-in-out 0.2s;
	z-index: 999;
}

.image_item_zoomed:hover {
	cursor: zoom-out;
	filter: brightness(1);
}

.yourTemlateForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.yourTemlateForm:hover {
	color: #007bff;
	border: 1px solid #007bff;

	svg {
		fill: #007bff;
	}
}
