.totals {
	margin-top: 16px;
}

.totalsItem {
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding: 8px 0;
}

.bb {
	border-bottom: 1px solid #ced4da;
}
