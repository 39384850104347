.placementTabs {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	color: #6c757d;
	letter-spacing: -0.05em;
	padding: 0 40px;

	border-bottom: 1px solid #ced4da;
}

.placementTab {
	padding: 12px 16px;
	position: relative;

	cursor: pointer;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	span {
		height: 18px;
		border-radius: 4px;
		padding: 3px 4px 3px 4px;
		margin-left: 4px;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.05em;
	}
}
.activeTab {
	color: #007bff;
}
