.campaignReportPage {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;

	padding: 1.5rem;
	height: 100%;
	width: 100%;
}
