.modal-media {
  min-width: 730px;
  max-width: 730px;
  .modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #212529;
  }
  .modal-body {
    padding: 40px;
    .row {
      margin-top: 24px;
    }
  }
  .form-label {
    &:first-of-type {
      margin-top: 0;
    }

    padding-left: 0;
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    /* identical to box height, or 150% */
    letter-spacing: -0.05em;
    color: #212529;

    div {
      input[type="checkbox"] {
        margin-right: 10px;

     }
    }
  }

  .form-check-input {
    cursor: pointer;
  }


  input[type="text"] {
    height: 46px;
    font-size: 20px;
    line-height: 30px;
    margin-top: 4px;
    letter-spacing: -0.05em;
    color: #495057;
  }
  input[type="number"] {
    height: 46px;
    font-size: 20px;
    line-height: 30px;
    margin-top: 4px;
    letter-spacing: -0.05em;
    color: #495057;
  }
  textarea {
    font-size: 16px;
    margin-top: 4px;
    //letter-spacing: -0.05em;
    color: #495057;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}