.body {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background: rgba(33, 37, 41, 0.65);
    width: 100vw;
    height: 100vh;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 95%;
    max-width: 95%;
    min-height: 95%;
    max-height: 95%;
    overflow: hidden;
}
.content img {
    max-height: 100%;
    max-width: 100%;
    width: inherit;
    height: inherit;
}