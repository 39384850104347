.selectorsCard {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	padding: 2.25rem;
	border-radius: 24px !important;
}

.selectorsHead {
	display: flex;
	flex-direction: column;
	gap: 8px;
	h2,
	p {
		margin: 0;
	}

	h2 {
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.03em;
	}
	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
.selectorsList {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.selectorItem {
	display: flex;
	align-items: center;
	justify-content: space-between;

	gap: 1rem;
	padding: 20px 16px;
	border: 1px solid #ced4da;
	border-radius: 16px;

	&:hover {
		border-color: #007bff;
		background-color: rgba(0, 123, 255, 0.1);

		svg {
			fill: #007bff;
		}
		h6 {
			color: #007bff;
		}
		span {
			color: #007bff;
		}
	}
}

.selectorItem_disabled {
	border-color: #ced4da;

	svg {
		fill: #ced4da;
	}
	h6 {
		color: #ced4da;
	}
	span {
		color: #007bff;
	}
}
.selectorBody {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;

	h6 {
		margin: 0;
		//styleName: Heading/H6;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}
.addedStatus {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	color: #28a745 !important;
	background-color: rgba(40, 167, 69, 0.1);

	padding: 8px;
	border-radius: 12px;
	svg {
		width: 1rem;
		height: 1rem;
		fill: #28a745 !important;
	}
}
.goRightIcon {
	width: 1rem;
	height: 1rem;
	fill: #6c757d;
}
.selectorStatus {
	display: flex;
	align-items: center;
	gap: 1rem;
	h6 {
		margin: 0;
	}
}

.selectorInfo {
	display: flex;
	align-items: center;

	gap: 1rem;
	flex: 1;
}

//mobile view
@media only screen and (max-width: 600px) {
	.selectorsCard {
		padding: 32px 16px;
		gap: 1rem;
	}

	.selectorInfo {
		flex-direction: column;

		span {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			font-size: 12px;
			font-weight: 400;
			line-height: 18px;

			border-radius: 10px;
		}
	}
	.selectorBody {
		width: 100%;
		gap: 1rem;
	}
	.selectorStatus {
		flex-direction: column;
		gap: 2px;
		align-items: flex-start;
		flex: 1;
	}
	.selectorItem {
		border-radius: 12px;

		h6 {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.selectorsHead {
		gap: 4px;
		h2 {
			font-size: 28px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: -0.03em;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}
	}
}
