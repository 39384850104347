.districtBookingFormat {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	gap: 1rem;
	max-width: 300px;
}
.districtBookingFormatControls {
	display: flex;
	align-items: flex-start;
	gap: 1rem;

	i,
	svg {
		color: #6c757d;
		fill: #6c757d;
		width: 16px;
		height: 16px;
	}
}
.formatPlacementsCount {
	color: #6c757d;
	margin: 0;
	font-size: 14px;

	&:hover {
		cursor: pointer;
		color: #007bff;
		text-decoration: underline;
	}
}
.placementsOverViewContainer {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.placementsOverView {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.placement {
	display: flex;
	align-items: center;
	gap: 24px;
}
.placementAddress {
	h5 {
		margin: 0;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.districtBookingFormat {
		max-width: 100% !important;
	}
	.placementAddress {
		h5 {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.03em;
			text-align: left;
		}
	}
}
