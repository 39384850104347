.placementsInfoList {
	padding: 1.5rem 1.5rem 0 1.5rem;
}

.placementDetatilsFooter {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	padding: 0.5rem 1.5rem 1.5rem 1.5rem;

	button {
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: -0.02em;
		border-radius: 1rem;
		padding: 9px, 20px;
	}
}
.placementCosts {
	display: flex;
	flex-direction: column;

	& > div:not(:last-child) {
		border-bottom: 1px solid #ced4da;
	}
}
.selectedShowingCountType {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0;
	p {
		margin: 0;
	}
}
.totalCount {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 0.5rem 0 0 0;
	h5 {
		margin: 0;

		line-height: 28px;
		letter-spacing: -0.02em;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.placementsInfoList {
		padding: 1rem 1rem 0 1rem;
	}
	.placementDetatilsFooter {
		padding: 1rem;
		gap: 0.75rem;

		button {
			font-size: 16px;
			line-height: 24px;
			padding: 8px 9.5px;
			border-radius: 12px;
		}
	}
	.selectedShowingCountType {
		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
	.totalCount {
		h5 {
			font-size: 16px;
			line-height: 24px;
		}
	}
}
