.table {
	border: 1px solid #dee2e6;
	overflow: hidden;

	&.rounded {
		border-radius: 8px;
	}
}

.header {
	display: flex;
}

.primaryHover {
	&:hover {
		color: #007bff;
	}
}
.row {
	display: flex;

	&:hover:not(.noHover) {
		background: rgba(0, 0, 0, 0.075);
	}

	&:last-child > .cell {
		border-bottom: 0;
	}

	& > .cell:last-child {
		border-right: 0;
	}
}

.cell {
	display: flex;
	justify-content: inherit;
	flex: 1;
	border-right: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	padding: 12px 16px 12px 16px;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: -0.03em;
	line-height: 22px;

	&.header {
		font-family: Inter;
		background-color: #f5f5f5;
		font-weight: 500;
	}

	&.noPadding {
		padding: 0;
	}
}

.faded {
	color: #ced4da;
}

.cellContent {
	display: flex;
	justify-content: inherit;
	flex: 1;

	&.ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.cellLeft,
.cellRight {
	display: flex;
	align-items: center;
}

.cellLeft {
	margin-right: 16px;
}

.cellRight {
	margin-left: 16px;
}

.iconButton {
	cursor: pointer;

	&:hover {
		stroke: #007bff;
	}
}

.iconMore {
	cursor: pointer;

	&:hover {
		transform: scale(1.2);
	}
}

.noBorder {
	border: 0;
}

.noBorderRight {
	border-right: 0;
}

.noBorderBottom {
	border-bottom: 0;
}

.noBorderTop {
	border-top: 0;
}

.noBorderLeft {
	border-left: 0;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
