.iconButtonDelete {
	display: flex;
	align-items: center;
	gap: 4px;
	background-color: #f5f5f5;
	border-radius: 4px;
	padding: 6px 12px;
	cursor: pointer;
	p {
		margin: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.04em;
		text-align: center;
	}

	&:hover {
		color: #dc3545;
	}
}
