.acticle {
	margin: 40px 140px 104px 140px;

	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.03em;

	section {
		margin-top: 40px;
	}
	ol {
		list-style-type: none;
		padding: 0;
	}

	ul {
		list-style: initial;
	}
}
.acticle_tablet {
	margin: 40px 64px;
}
.acticle_mobile {
	margin: 24px 16px;
}
.li_text {
	display: flex;
	span {
		white-space: pre;
	}
}
.img {
	width: 100%;

	border-radius: 8px;
	border: 1px solid #ced4da;
	object-fit: cover;
	background-color: #ffff;
}
.img_mobile {
	height: 162px;
}

.marker {
	margin-bottom: 40px;
	text-align: left;
}
.marker_mobile {
	margin-bottom: 24px;
}

.title {
	margin-bottom: 24px;
}

.child {
	padding: 24px;
	border-radius: 8px;
	background-color: #f5f5f5;
	margin-bottom: 24px;
	width: 100%;
}
