.reklamnie-nositeli-info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;

	&__image_container {
		max-height: 320px;
		overflow: hidden;
		max-width: 100%;
		background-color: #f5f5f5;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		margin-top: 2px;
		img {
			height: 100%;
			width: 100%;
			//object-fit: cover;
		}
	}
	.scrollable {
		flex-grow: 1;
		height: 100%;
		padding: 24px 24px 0 24px;
		overflow-y: auto;
	}
	.scrollable::-webkit-scrollbar-track {
		//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		background-color: transparent;
		border-radius: 10px;
	}

	.scrollable::-webkit-scrollbar {
		width: 5px;
		background-color: transparent;
	}

	.scrollable::-webkit-scrollbar-thumb {
		background-color: rgba(33, 37, 41, 0.3);
		border-radius: 10px;
	}
	.scrollable::-webkit-scrollbar-thumb:hover {
		background-color: rgba(33, 37, 41, 0.5);
		border-radius: 10px;
	}
	&__controls {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: auto;
		flex-wrap: nowrap;
		border-top: 1px solid #ced4da;
		//z-index: 10001;
		.control-btn {
			min-height: 100%;
			//width: 89px;
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #6c757d;
			//justify-content: center;
			padding: 15px 12px;
			cursor: pointer;
			margin-top: 1px;
			overflow: hidden;
			&:hover {
				color: #007bff;
				background: #f5f5f5;
				i {
					fill: #007bff;
				}
			}
			i {
				display: flex;
				justify-content: center;
				flex-direction: column;
				font-size: 24px;
				font-weight: bold;
			}
			span {
				//font-size: 14px;
			}
		}
	}
}
