.garantidMediaFormatCard {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	border: 1px solid #ced4da;
	border-radius: 1.5rem;
	padding: 1.5rem;
}

.garantidItemsContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& > div:not(:last-child) {
		padding-bottom: 1rem;
		border-bottom: 1px solid #ced4da;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.garantidMediaFormatCard {
		padding: 1rem;
	}
}
