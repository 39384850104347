.advFormatNav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	button {
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: -0.02em;

		border-radius: 16px;
	}
}
.advFormatActionContainer {
	display: flex;
	align-items: center;
	gap: 8px;
}

//mobile view
@media only screen and (max-width: 600px) {
	.advFormatNav {
		gap: 0.5rem;
		button {
			padding: 8px 16px;
			border-radius: 12px;

			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}

		div {
			flex: 1;
			button:last-child {
				display: flex;
				justify-content: center;

				flex: 1;
			}
		}
	}
}
