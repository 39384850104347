.providerCheckbox {
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 8px;
	padding: 8px;
	border-radius: 4px;
}
.providerCheckbox:hover {
	background-color: #f5f5f5;
}
