.actionButtons {
	display: flex;
	gap: 0.5rem;
}
.actionButtons_requirements {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;

	svg {
		display: none;
	}
}

.actionButtons_remove {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	span {
		display: none;
	}
}
.actionButtons_upload {
	background-color: #f5f5f5;

	svg,
	i {
		color: #212529;
		fill: #212529;
	}
	&:hover {
		background-color: #fff;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.actionButtons {
		flex-direction: column;
	}

	.actionButtons_requirements {
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.03em;

		svg {
			display: block;
			fill: #6c757d;
		}
	}
	.actionButtons_remove {
		span {
			display: block;
			font-weight: 500;
			letter-spacing: -0.03em;
		}
	}
}
