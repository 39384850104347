.summaryList {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.summaryItem {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:not(:last-child) {
		border-bottom: 1px solid #ced4da;
		padding-bottom: 12px;
	}
	p,
	h6 {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.summaryList {
		gap: 8px;
	}
	.summaryItem {
		&:not(:last-child) {
			padding-bottom: 8px;
		}
		p,
		h6 {
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: -0.03em;
		}
	}
}
