.trigger {
	cursor: pointer;
	height: fit-content;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-right: 40px;
	gap: 4px;
	position: relative;
}
.itemSelected {
	background-color: #f5f5f5;
	border: 1px solid #d6d8db;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px 8px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.04em;
	color: #212529;
	display: inline-block;
	transition: background-color 0.3s ease;
	i {
		font-size: 19px;
		margin-bottom: -90px;
		&:hover {
			color: red;
		}
	}
	&:hover {
		background-color: #eeeeeeff;
	}
}
.menu {
	background: #ffffff;
	display: flex;
	flex-direction: column;
	-webkit-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	max-height: 600px;
	margin-bottom: 20px;
	border: 1px solid #ced4da;
	border-radius: 4px;
}
.search {
	min-height: 72px;
	padding: 16px 24px;
	border-bottom: 1px solid #ced4da;
	position: relative;

	& > i {
		position: absolute;
		top: 23px;
		left: 39px;
		color: #6c757d;
	}
	& > input {
		padding-left: 56px;
	}
}
.menuData {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: 500px;
	min-height: 250px;
}
.menuControls {
	height: 64px;
	min-height: 64px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 32px;
	padding-right: 16px;
	border-top: 1px solid #ced4da;
	a {
		text-decoration: none;
		font-weight: 500;
		font-size: 16px;
	}
}
.menuItem {
	height: 40px;
	min-height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	&:hover {
		background: #f5f5f5;
	}
}
.menuItemWithDesc {
	height: 78px;
	min-height: 78px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
	&:hover {
		background: #f5f5f5;
	}
	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		display: flex;
		align-items: center;
		letter-spacing: -0.03em;
		color: #6c757d;
	}
}
