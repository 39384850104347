#ymaps_provider_placement_picker {
	background: #007bff;
	height: 80px;
	padding: 8px 16px;
	border: 2px solid #ffffff;
	border-radius: 4px;
	display: flex;
	max-height: 80px;
	max-width: 255px;
	min-width: 255px;
	overflow: hidden;
	flex-direction: column;
	//pointer-events: none;
	-webkit-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	position: static;

	.content {
		flex-grow: 1;
	}

	h6 {
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: #ffffff;
		font-weight: 500;
		font-family: Inter;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.04em;
		margin: 0;
	}
	span {
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: -0.03em;
		color: rgba(255, 255, 255, 0.6);
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	button {
		background: #007bff;
		border-radius: 4px;
		border: none;
		color: #fff;
		width: 100%;
		padding: 6px 0;
	}
	&::after {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #007bff;
		border: 2px solid #ffffff;
		position: absolute;
		top: 85px;
		left: 123px;
	}
}
