.phoneConfirmationContainer {
	display: flex;
	flex-direction: column;
	gap: 2.25rem;
	flex: 1 1 auto;
}

.phoneConfirmationBody {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.phoneConfirmationButtons {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.confirmationText {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
}

.confirmationCode {
	input {
		border-radius: 1rem;
	}
}
.timerText {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
}
.phoneConfirmationVariants {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;

	button {
		//styleName: Heading/H6;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}
.backButton {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	border-radius: 1rem !important;

	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: -0.02em;

	i {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.phoneConfirmationVariants {
		gap: 0.75rem;
	}
	.confirmationCode {
		input {
			border-radius: 0.75rem;
		}
	}
	.phoneConfirmationContainer {
		justify-content: space-between;
	}
	.backButton {
		border-radius: 0.75rem !important;
	}
}
