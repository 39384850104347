.itemCheckBox {
	max-width: 48px;
	min-width: 48px;
	border-right: 1px solid #ced4da;
	display: flex;
	justify-content: center;
	padding-top: 16px;
	font-size: 12px;
	cursor: pointer;

	input {
		cursor: pointer;
	}
}
