.districtBookingFormatContainer {
	display: flex;
	justify-content: flex-start;
	gap: 40px;
	flex-wrap: wrap;
}

//mobile view
@media only screen and (max-width: 900px) {
	.districtBookingFormatContainer {
		gap: 1rem;
		flex-direction: column;

		& > div:not(:last-child) {
			padding-bottom: 1rem;
			border-bottom: 1px solid #ced4da;
		}
	}
}
