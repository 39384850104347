.item {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	&:not(:last-child) {
		border-bottom: 1px solid #ced4da;
		padding-bottom: 1.5rem;
	}
	&:not(:first-child) {
		padding-top: 1.5rem;
	}
}
.inputs {
	display: flex;
	gap: 1rem;
}

.counts {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	svg {
		width: 16px;
		height: 16px;
	}
}
.countsText {
	display: flex;
	gap: 0.5rem;
	h6 {
		margin: 0;
		gap: 0.5rem;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}
.controlCity {
	flex: 2;
}
.controlBudget {
	flex: 1;
}
.controlCity,
.controlBudget {
	label {
		line-height: 24px;
		letter-spacing: -0.03em;
	}
}

.roundedInput {
	border-radius: 16px !important;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.itemsList {
	display: flex;
	flex-direction: column;
}
.summary {
}

//mobile view
@media only screen and (max-width: 600px) {
	.content {
		gap: 1rem;
	}
	.item {
		&:not(:last-child) {
			border-bottom: 1px solid #ced4da;
			padding-bottom: 1rem;
		}
		&:not(:first-child) {
			padding-top: 1rem;
		}
	}
	.inputs {
		display: flex;
		flex-direction: column;
	}

	.countsText {
		display: flex;
		gap: 0.5rem;
		h6 {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.03em;
		}
	}
}
