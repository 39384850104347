.provider-sidebar {
  position: fixed;
  top: 65px;
  height: calc(100vh - 65px);
  //height: 100%;
  z-index: 1;
  background: #212529;
  border-right: 1px solid #ced4da;
  //padding: 23px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  color: #ffffff;
  left: -240px;
  transition: all .5s ease-in-out;
  @media screen and (max-width:600px) {
    top: 51px;
    height: calc(100vh - 51px);
  }
    &_force {
      left: 0;
      z-index: 11;

    }
  &_absolute {
    //position: absolute;
    //top: 0px;
  }
  &__hidden {
    position: absolute;
    width: 25px;
    height: inherit;
    background: transparent;
    left: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      margin-bottom: 80px;

      path {
        fill: #6C757D;
      }
      transform: rotate(180deg);
    }
    &:hover {
      background: #E5E5E5;
      color: #212529;
      svg {
        path {
          fill: #212529;
        }
      }
    }

  }
  &_force   &__hidden {
    pointer-events: none;
    display: none;
  }
  &__routes {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.04em;
      padding: 13px 24px;
      color: rgba(255, 255, 255, 0.5);
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }
      span {
        height: fit-content;
        background-color: rgba(255, 255, 255, 0.1)!important;
        color: rgba(255, 255, 255, 0.5)!important;
      }
    }
    a.active {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      span {
        background-color: #007BFF!important;
        color: rgba(255, 255, 255, 1)!important;
      }
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);;
    a {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.03em;
      color: rgba(255, 255, 255, 0.5);
    }
    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */

      letter-spacing: -0.03em;

      color: rgba(255, 255, 255, 0.5);
    }
  }
  &__hide_btn {
    position: absolute;
    width: 32px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0 0 4px;
    left: calc(100% - 32px);
    top: calc(50% - 80px);
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);;

    svg {
      path {
        fill: rgba(255, 255, 255, 0.5);;
      }
    }

    &:hover {
    background: rgba(255, 255, 255, 0.1);
      svg {
        path {
          fill: #fff;
      }
    }
    }
  }


}
.tooltip-inner {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: -0.05em;
  max-width: 250px;
}