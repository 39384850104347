.profileContainer {
  padding: 40px 40px 0 40px;
  width: 100%;
  max-width: 1440px;
  overflow-x: hidden;

  @media (max-width:1132px) {
    padding: 24px 24px 0 24px;
  }

  @media (max-width:600px) {
    padding: 16px 8px;
  }

  h1 {
    padding: 0;
    margin: 0 0 24px;
    font-weight: 500;
  }
}

.profileSection {
  background-color: #FFFFFF;
  padding: 40px;
  margin-bottom: 16px;
  border-radius: 8px;

  @media (max-width:1132px) {
    padding: 24px;
  }

  @media (max-width:600px) {
    padding: 24px 16px;
  }

  h3 {
    margin: 0;
    padding: 0 0 16px;
  }
}

.personal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width:1132px) {
    grid-template-columns: 1fr;
  }
}

.personalInfo {
  padding-top: 24px;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width:1132px) {
    margin-right: 0;
    //padding-bottom: 24px;
  }

  @media (max-width:600px) {
    flex-direction: column;
  }
}

.trash {
  margin-top: 8px;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 10px;

  i {
    color: #6C757D;

  }
}

.trash:hover {
  background: #F5F5F5;

  i {
    color: #DC3545;
  }
}

.myMoney {
  width: 1040px;
  height: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #212529;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.balanceTitile {
  width: 1040px;
  height: 32px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #212529;
  flex: none;
  order: 0;
  flex-grow: 0;
}



.dropDown {
  width: 1040px;
  height: 0px;
  border: 1px solid #CED4DA;
  flex: none;
  order: 1;
  flex-grow: 0;
}


.iconBalance {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 12px;
  isolation: isolate;
  width: 164px;
  height: 64px;
  background: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  justify-content: center;
}




.imgWallet {

  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  margin-left: 10px;


}

.walletSum {
  width: 88px;
  height: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6C757D;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
}


.driveRight {
  position: absolute;
  width: 64px;
  right: -64px;
  top: 64px;
  bottom: 0px;
  border: 1px solid #CED4DA;
  transform: rotate(-90deg);
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}