.viewsReportTable {
	width: 100%;

	border-collapse: separate;
	border-spacing: 0;
	border-radius: 16px;
	border: 1px solid #ced4da;

	overflow: hidden;
	th,
	td {
		border-bottom: 1px solid #ced4da;
	}

	th {
		height: 48px;
		padding: 0;
		div {
			width: 100%;
			height: 100%;
			padding: 12px 16px;
		}
	}

	thead {
		tr {
			background-color: #f5f5f5;
			th:not(:last-child) {
				border-right: 1px solid #ced4da;
			}
		}

		tr:first-child {
			th:first-child {
				border-bottom: none;
			}
		}
		tr:last-child {
			th:first-child {
				h6 {
					visibility: hidden;
				}
			}
		}
	}
	tbody {
		td:not(:last-child) {
			border-right: 1px solid #ced4da;
		}

		tr:last-child {
			td {
				border-bottom: none;
			}
		}
	}
	// thead {
	// 	tr:first-child {
	// 		th:first-child {
	// 			border: 1px solid red;
	// 			border-radius: 16px;
	// 		}
	// 	}
	// }
}
