.content {
	max-height: 100%;
	height: 100%;
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	// flex-grow: 1;
	border-top: 1px solid #ced4da;
	background-color: #ffffff;
}

.campaingList {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.campaingListHeader {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #ced4da;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.03em;
	text-align: left;
}
.campaingListHeaderName {
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 16px;
}
.campaingListHeaderStatus {
	width: 120px;
	padding-left: 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-left: 1px solid #ced4da;
}
.campaignItemInfo {
	border-left: 1px solid #ced4da;
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.campaignItemsList {
	display: flex;
	flex-grow: 1;
	overflow-y: auto;
	flex-direction: column;
	width: 100%;
}
.campaignItem {
	border-bottom: 1px solid #ced4da;
	display: flex;
	width: 100%;
}
.campaignItem_active {
	background-color: #f5f5f5;
}
.campaignItemStatus {
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: -0.03em;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.campaignItemName {
	//styleName: Heading/H6;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: left;
	color: #212529;
}
.campaignItemFieldInfo {
	display: flex;
	justify-content: flex-start;
}
.campaignItemFieldInfoName {
	//styleName: Body/Caption;
	width: 116px;
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.03em;
	text-align: left;
	color: #6c757d;
}
.campaignItemFieldInfoValue {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.03em;
	text-align: left;
	color: #212529;
}
.detailModalHeader {
	h4 {
		//styleName: Heading/H4;
		font-family: Inter;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.04em;
		text-align: left;
		margin: 0;
	}
}

.bottomButton {
	border: none;
	background-color: inherit;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: min-content;
	padding: 17px 12px;
	height: '100%';
	p {
		margin: 0;
		font-family: Inter;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: -0.04em;
		text-align: center;
	}
	&:hover {
		background-color: #f5f5f5;
	}

	&:disabled {
		opacity: 0.5;
	}
}
