.listItem {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin-bottom: 1px;
}

.listItemContent,
.nestedItemContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	cursor: pointer;

	h6 {
		margin: 0;
		line-height: 24px;
		letter-spacing: -0.03em;
		color: #6c757d;
	}

	button {
		border: none;
		background-color: transparent;
		max-height: 24px;
		border-radius: 50%;
		margin: 0;
		padding: 0;
	}

	&:hover {
		background-color: #f5f5f5;
	}
}
.listItemContent {
	padding: 12px 16px;
}
.nestedItemContent {
	padding: 12px 32px;
}
.open {
	svg {
		vertical-align: top;
	}
}
.bold {
	color: #212529 !important;
}
.covered {
	background-color: #f5f5f5;
}
