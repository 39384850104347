.filterWrapper {
	display: flex;
	flex-direction: column;
	padding: 18px 40px;
	gap: 8px;
}

.filterRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.filterSide {
	display: flex;
	gap: 8px;
}
.filterSide_fullWidth {
	width: 100%;
	margin-right: 8px;
}
.iconButton {
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: center;
}

.deleteFilterButton {
	height: 100%;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;
	cursor: pointer;
	border-radius: 4px;
	&:hover {
		transform: scale(1.2);
		background: rgb(225, 228, 231);
	}
}
.modalContent {
	padding: 0;
	max-height: calc(100vh - 120px - 72px - 80px - 100px);
	height: 100vh;
	display: flex;
	flex-direction: column;
}
.modalTab {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	cursor: pointer;
	border-radius: 4px;
}
.modalTab_active {
	background-color: #f5f5f5;
}
