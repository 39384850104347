.links {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 24px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);

	a,
	p {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: -0.03em;
		color: rgba(255, 255, 255, 0.5);
	}
}
