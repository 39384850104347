.article {
	h6 {
		line-height: 24px;
		letter-spacing: -0.03em;
		border-bottom: 1px solid #ced4da;
		padding: 0 0 0.5rem 0;
		margin: 0;
	}
}

.articleItem {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0.5rem 0;
	border-bottom: 1px solid #ced4da;
	gap: 1rem;

	ul {
		margin: 0;
		padding: 0;
		list-style-position: inside;
	}

	div {
		font-family: Inter;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.01em;
		text-align: left;
	}
	span {
		letter-spacing: -0.04em;
		color: #6c757d;
		min-width: 186px;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.article {
		img {
			width: 100%;
		}
	}
	.articleItem {
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 0.5rem;
		span {
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.03em;
			min-width: 20%;
		}
		div {
			font-family: Inter;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.03em;
		}
	}
}
