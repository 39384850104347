.advIssuesList {
	padding: 0;
	border-radius: 8px;
}
.advIssue {
	padding: 12px;

	p {
		font-size: 12px;
		line-height: 16px;
		letter-spacing: -0.03em;

		margin: 0;
	}
	h6 {
		margin: 0;
	}
}

.advIssuesList > .advIssue:not(:last-child) {
	border-bottom: 1px solid #ced4da;
}
