.customRange {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 0.3rem;
	background: transparent;
	cursor: pointer;
}

.customRange::-webkit-slider-runnable-track {
	border-radius: 0.3rem;
	height: 0.3rem;
}
.customRange::-moz-range-track {
	height: 0.3rem;
}

.customRange::-webkit-slider-thumb {
	-webkit-appearance: none; /* Override default look */
	appearance: none;
	margin-top: -10px; /* Centers thumb on the track */
	background-color: white;
	border-radius: 50%;
	outline: 0.3rem solid #007bff;
	height: 1.5rem;
	width: 1.5rem;
}

.customRange::-moz-range-thumb {
	border: none;
	border-radius: 50%;
	background-color: white;
	outline: 0.3rem solid #007bff;
	height: 1.5rem;
	width: 1.5rem;
}

@media only screen and (max-width: 600px) {
	.customRange::-webkit-slider-thumb {
		height: 1.625rem;
		width: 1.625rem;
		margin-top: -10px;
	}
	.customRange::-moz-range-thumb {
		height: 1.625rem;
		width: 1.625rem;
	}
}
