.reportDocument {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 17px 14px;
	border-radius: 8px;
	cursor: pointer;

	> div {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	&:hover {
		background: #f5f5f5;
		color: #007bff;
		svg {
			fill: #007bff;
		}
	}
}
