.distictsUploadContainer {
	padding: 40px;
	gap: 1.5rem;
}
.headerWithDescrption {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	p {
		margin: 0;
	}
}
.headerTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	h5 {
		margin: 0;
	}
}
.distictsUploadItemsContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.distictsUploadItem {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	border: 1px solid #ced4da !important;
	border-radius: 1.5rem !important;
	padding: 1.5rem;
}
.distictsUploadItemHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	border-radius: 1.5rem 1.5rem 0 0;
	background-color: inherit;
	padding: 0;
	h5 {
		margin: 0;

		font-size: 20px;
		line-height: 28px;
		letter-spacing: -0.02em;
	}
	span {
		color: #6c757d;
		&:hover {
			text-decoration: underline;
			color: #212529;
			cursor: pointer;
		}
	}
}
.distictsUploadItemFooter {
	display: flex;
	gap: 1rem;
	width: 100%;

	button {
		flex: 1 1;
	}

	h6 {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}
@media only screen and (max-width: 600px) {
	.headerWithDescrption {
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		text-align: left;
	}
	.headerTitle {
		h5 {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.distictsUploadContainer {
		padding: 1rem;
	}
	.distictsUploadItemsContainer {
		gap: 0.5rem;
	}
	.distictsUploadItem {
		padding: 1rem;
	}
	.distictsUploadItemHeader {
		h5 {
			font-size: 16px;
			line-height: 24px;
		}
		span {
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
		}
	}
	.distictsUploadItemFooter {
		flex-direction: column;
		gap: 0.5rem;
	}
}
