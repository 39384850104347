.backButton {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-top: 40px;
	padding-left: 24px;
}
.backButton_mobile {
	padding-top: 0px;
	padding-left: 0px;
}
.mainContentWrapper {
	display: flex;
	flex-direction: column;
}
.reportContent {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap-reverse;
	align-items: flex-end;
}
.reportContent_mobile {
	align-items: center;
}
