.tvItem_header {
	display: flex;
	justify-content: space-between;
}
.tvItem_headerInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1.5rem;
	width: 100%;

	h5 {
		margin: 0;
	}
}
.tvItem_headerTitle {
	display: flex;
	align-items: center;
	gap: 1.5rem;

	svg {
		width: 40px;
		height: 40px;
	}
}

.menuContainer {
	min-width: 48px;
	width: 48px;
	display: none;

	button {
		padding-top: 0;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.tvItem_headerInfo {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;

		h5 {
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.03em;
		}
	}
	.tvItem_headerTitle {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
	.menuContainer {
		display: block;
	}
}
