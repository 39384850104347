.commonMedia {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0;
}
.commonMediaControls {
	display: flex;
	gap: 1rem;

	button {
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}

	i,
	svg {
		color: #6c757d;
		fill: #6c757d;
		width: 16px;
		height: 16px;
	}
}
.mediaPreviewContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	width: 50%;
	border: 1px dashed #28a745;
	border-radius: 1rem;
	padding: 10px;
}

.mediaPreview {
	display: flex;
	gap: 1rem;
	align-items: center;

	p {
		margin: 0;
		font-size: 14px;
		line-height: 22px;
		max-width: 120px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
.commonMediaControls_primary {
	i,
	svg {
		fill: #212529;
		color: #212529;
	}

	&:hover {
		color: #212529;
		i,
		svg {
			fill: #212529;
			color: #212529;
		}
	}
}
.commonMediaControls_danger {
	&:hover {
		i,
		svg {
			fill: #212529;
			color: #212529;
		}
	}
}

.commonMediaEditContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.commonMediaEdit {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
}
.commonMediaEditUpload {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	p {
		margin: 0;
	}
}
.commonMediaEditFooter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid #ced4da;
	gap: 1rem;
	padding: 1rem;
}
.massDocsUploadContainer {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	p {
		margin: 0;
	}
}
//mobile view
@media only screen and (max-width: 900px) {
	.commonMedia {
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
	}
	.commonMediaControls {
		width: 100%;
	}
	.mediaPreviewContainer {
		width: 100%;
	}
	.mediaPreview {
		max-width: 100%;
		overflow: hidden;
		p {
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
