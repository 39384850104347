.mobileModal {
	max-height: 100vh;
	overflow: hidden;
	position: relative;
}
.mobileModalHeaderPrimary {
	display: flex;
	justify-content: space-between;

	align-items: center;
	overflow: hidden; /* Скрытие избыточного текста */
	text-overflow: ellipsis;
	border-bottom: 1px solid #e9ecef;
	padding: 0 0 0 12px;
	p {
		margin: 0;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.03em;
		text-align: left;
	}
	button {
		svg {
			width: 1rem;
			height: 1rem;
			fill: #6c757d;
		}
	}
}
.mobileModalBody {
	padding: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	position: relative;
}
.searchWrapper {
	padding: 0.75rem 1rem;
}
.searchResultsList {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	max-height: 100%;
	background: white;
	z-index: 999;
}
.searchResultsList::before {
	content: '';
	height: 10px;
	width: '100%';
	box-shadow: 0px 5px 10px rgba(33, 37, 41, 0.1); /* Выберите нужные параметры тени */
	z-index: 1000; /* Чтобы тень находилась над содержимым элемента */
}
.expandWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	gap: 10px;
}
.radiusWrapper {
	position: relative;
	padding: 0.5rem 1.5rem 1rem 1.5rem;
	border-bottom: 1px solid #e9ecef;
}
.bottomSheet {
	width: 100%;
	border-bottom: 1px solid #e9ecef;
	position: absolute;
	z-index: 3;
	background: white;
	bottom: 0;
	height: min-content;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.addedPlacementsMobile {
	position: absolute;
	background: #fff;
	top: 0;
	left: 0;
	z-index: 9999;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.addedPlacementsMobile_header {
	border-bottom: 1px solid #e5e5e5;
	min-height: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	p {
		font-size: 12px;
		letter-spacing: -0.03em;
	}
}
.addedPlacementsMobile_headerBackButton {
	height: 100%;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	justify-self: flex-start;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
