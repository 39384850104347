.advNav {
	flex-direction: column-reverse;

	button {
		width: 100%;
	}
	div {
		flex-direction: column-reverse;
		width: 100%;
	}
}
