.autocomplete-input {
  &__menu {
    max-height: 360px;
    overflow: auto;
  }

  &__option {
    padding: 8px 24px;
  }

  &__option-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__option-data {
    color: #6c757d;
    font-size: 14px;
    line-height: 20px;
  }
}
