.cardWrapper {
	padding: 40px;
	border-radius: 24px !important;
}
//mobile view

@media only screen and (max-width: 600px) {
	.cardWrapper {
		padding: 24px 16px;
		border-radius: 20px !important;
	}
}
