$primary01: #007bff;

$dark01: #212529;

$gray02: #949494;
$--bs-blue: #007bff;

:root {
  --bs-primary: #007bff;
  --bs-blue: #007bff;
  --bs-success-rgb: 40, 167, 69;
  --bs-primary-rgb: 0, 123, 255;
}
$--bs-primary: #007bff;
