.desktopLayout {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	height: 100%;
	& > div:first-child {
		padding: 24px;
		width: 360px;
		min-width: 360px;
		max-width: 360px;
		border-right: 1px solid #ced4da;
	}
	& > div:last-child {
		flex: 1 1 auto;
		padding: 24px;
		overflow: auto;
	}
}

.forecastTable {
	width: inherit;
	border-collapse: separate !important;
	border-spacing: 0px;
	& > tbody > tr > th {
		border: 1px solid #ced4da;
		padding: 12px 16px;
		background: #f5f5f5;
		font-weight: 500;
		color: #212529;
	}
	& > tbody > tr > td {
		border: 1px solid #ced4da;
		padding: 12px 16px;
	}
	
}



.mediaplanLoad{width: 68px;
height: 24px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */
text-align: right;

letter-spacing: -0.03em;

/* Text / Secondary */

color: #6C757D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;}


.tdNumbers{
text-align: right;
}


.mediaplanTime{
	flex: none;
	order: 1;
	flex-grow: 0;




	& > img {
		width: 16px;
height: 16px;
margin-top: -1px;
	}


}
