.garantidCard {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	border: none;
	border-radius: 1.5rem;
	padding: 40px;
}
.garantidMediaCardHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	border-radius: 1.5rem 1.5rem 0 0;
	background-color: inherit;
	padding: 0;

	h5 {
		margin: 0;
	}
}
.garantidMediaCardHeader_title {
	display: flex;
	gap: 1.5rem;
	align-items: center;
}
.cardsContainer {
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
//mobile view
@media only screen and (max-width: 600px) {
	.garantidCard {
		padding: 1rem;
		gap: 1rem;
	}
	.garantidMediaCardHeader {
		h5 {
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.03em;
		}
	}
	.garantidMediaCardHeader_title {
		gap: 0.5rem;
	}
	.cardsContainer {
		gap: 1rem;
	}
}
