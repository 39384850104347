.card {
	width: 100%;
	position: absolute;
	background-color: #ffffff;
	border: 1px solid #ced4da;
	margin-top: 8px;
	box-shadow: 0px 4px 20px 0px #2125291a;
	border-radius: 4px;
	padding: 8px 0;
	z-index: 9999;
	max-height: 50vh;
	overflow-y: auto;
}

.list {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.listItem {
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: left;
	padding: 8px 24px;
}
.listItem:hover {
	background-color: #f5f5f5;
}
