.bottomNav {
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		border-radius: 16px;

		padding: 8px 20px;
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: -0.02em;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.bottomNav {
		gap: 0.5rem;
		button {
			border-radius: 12px;
			padding: 8px 16px;

			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}

		button:last-child {
			flex: 1;
		}
	}
}
