.accordionItem {
}
.accordionHeader {
	display: flex;
	align-items: center;
	gap: 16px;

	h6 {
		margin: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: left;
	}
	button {
		padding: 12px;
		background-color: #ced4da;
		color: #212529;
	}
	button:not(.collapsed) {
		color: #212529;
		background-color: #f5f5f5;
	}
	button:not(.collapsed)::after {
		background-image: url(../../../../../../../assets/images/select-hide.svg);
	}
}

.accordionBody {
	padding: 0;
	border-bottom: 1px solid #ced4da;
	> div {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
	}
}
