.chatIcon {
	height: 64px;
	width: 64px;
	min-width: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;

	svg {
		fill: #6c757d;
	}
	@media (max-width: 600px) {
		width: 40px;
		height: 40px;
		min-width: 40px;

		svg {
			height: 16px;
			width: 16px;
		}
	}
	&:hover {
		background-color: #f5f5f5;

		svg {
			path {
				fill: #212529;
			}
		}
	}
}

.container {
	position: fixed;
	width: 358px;
	height: 714px;
	overflow: hidden;
	border: 1px solid #ced4da;
	box-shadow: 0 4px 20px rgba(33, 37, 41, 0.1);
	border-radius: 8px;
	background-color: #ffffff;
	right: 16px;
	top: 79px;
	z-index: 1000;

	header {
		width: 100%;
		height: 64px;
		background-color: #ffffff;
		border-bottom: 1px solid #ced4da;
		position: static;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 28px 0 16px;
		h5 {
			margin: 0;
			font-size: 20px;
			line-height: 28px;
		}
		a {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			text-decoration: none;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: -0.04em;
		}
	}

	section {
		height: calc(100% - 64px);
	}
}
.pageContainer {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	header {
		width: 100%;
		height: 48px;
		@media (max-width: 600px) {
			height: 40px;
		}
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		border-bottom: 1px solid #ced4da;
		background-color: #ffffff;
		z-index: 2;
		.goBack {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding: 0 13px;
			position: absolute;
			top: 0;
			left: 0;
			i {
				color: #6c757d;
				font-size: 24px;
				@media (max-width: 600px) {
					font-size: 20px;
				}
			}
		}
		span {
			font-size: 14px;
			line-height: 20px;
			@media (max-width: 600px) {
				font-size: 12px;
				line-height: 20px;
			}
		}
	}
	section {
		height: 100%;
		padding: 48px 24px 0 24px;
		overflow-y: hidden;
		@media (max-width: 600px) {
			padding: 40px 0px 16px 0px;
		}
	}
}
