.internetupload {
  &__yandex {
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 8px;
    //margin-bottom: 40px;
    letter-spacing: normal;

    h6 {
      font-family: Arial;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      color: #020c7e;
      margin-bottom: 0;
      letter-spacing: normal;
    }
    h5 {
      font-family: Arial;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      color: #095f09;
      margin-bottom: 0;
      letter-spacing: normal;
    }
    span {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: normal;
    }
    .ad {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: #8b93a5;
      letter-spacing: normal;
    }
  }
  &__google {
    border: 1px solid #ced4da;
    border-radius: 8px;
    margin-top: 8px;
    //margin-bottom: 40px;
    letter-spacing: normal;
    padding: 16px;
    span {
      font-family: Arial;
      font-style: normal;
      font-size: 14px;
      color: #202124;
      letter-spacing: normal;
      cursor: pointer;
    }
    .ad {
      font-weight: bold;
    }
    h5 {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      color: #1c1ba8;
      letter-spacing: normal;
      margin-bottom: 6px;
      margin-top: 6px;
      cursor: pointer;
    }
    h6 {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: #333333;
      letter-spacing: normal;
      margin-bottom: 0;
    }
  }
}
