.content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
//mobile view
@media only screen and (max-width: 600px) {
	.content {
		gap: 1rem;
	}
}
