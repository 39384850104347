.header {
	display: flex;
	align-items: center;
	justify-content: center;
	//styleName: Heading/H6;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.03em;
	text-align: left;
	padding: 1rem;
	border-bottom: 1px solid #ced4da;
}
.contentWrapper {
	padding: 1.5rem;
}
.content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

//mobile view
@media only screen and (max-width: 600px) {
	.contentWrapper {
		padding: 1rem;
	}
	.content {
		gap: 1rem;
	}
}
