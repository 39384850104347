.provider-page {
	height: 100%;
	//background: #fff;
	position: relative;
	width: 100%;
}

.provider-page-title {
	position: absolute;
	top: 0;
	left: 0;
	height: 64px;
	display: flex;
	align-items: center;
	border-left: 1px solid #ced4da;
	margin-left: 240px;
	//transition: margin 0.1s ease-in;
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		padding-left: 40px;
		/* identical to box height, or 140% */

		letter-spacing: -0.04em;
		color: #212529;
	}
}
