.tRow {
	border-bottom: 1px solid #ced4da;

	td,
	th {
		padding: 12px 16px;
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.03em;
		text-align: left;
		border-right: 1px solid #ced4da;
	}

	td {
		font-weight: 400;
	}
	th {
		font-weight: 500;
		border-right: 1px solid #ced4da;
	}
}
.sendNotifyCell {
	border-right: none;
	cursor: pointer;
	svg {
		fill: #6c757d;
	}
	&:hover {
		background-color: #f5f5f5;

		svg {
			fill: #007bff;
		}
	}
}
