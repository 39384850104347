.recommmendationsLink {
	padding: 1rem;
	background-color: #f5f5f5;
	border-radius: 1rem;

	display: flex;
	align-items: center;
	gap: 1rem;

	&:hover {
		cursor: pointer;
		background-color: #007bff1a;
		p,
		h6 {
			color: #007bff;
		}

		svg {
			fill: #007bff !important;
		}
	}
}

.linkText {
	flex: 1;

	display: flex;
	flex-direction: column;

	h6 {
		margin: 0;

		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	p {
		margin: 0;

		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #6c757d;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.linkText {
		gap: 4px;

		h6 {
			font-size: 14px;
			font-weight: 500;
			line-height: 18px;
		}

		p {
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
		}
	}
}
