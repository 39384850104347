.container {
	width: 100%;
	color: #212529;
}

.tabs {
	display: flex;
	flex-direction: row;
}

.tab {
	flex: 1;
	padding: 13px 0;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
	border-bottom: 1px solid #ced4da;
	text-align: center;

	&.active {
		color: #007bff;
		border-bottom: 2px solid #007bff;
	}
}
