.modalHeader {
	h5 {
		line-height: 28px;
		letter-spacing: -0.04em;
		margin: 0;
	}
}
.modalHeader_mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h5 {
		//styleName: Body/Caption;

		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.03em;
		margin: 0;
	}
}
.modalAdvInfo {
	//styleName: Heading/H6;
	h6 {
		line-height: 24px;
		letter-spacing: -0.03em;
		margin: 0;
	}
}
