.photoReport {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
}
.photoReportItem {
	display: flex;
	flex-direction: column;
	gap: 12px;

	p {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	img {
		width: 100%;
		border-radius: 12px;
		max-height: 278px;
	}
}
.photoReportBackdrop {
	z-index: 9999;
}
