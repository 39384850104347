.bottomAlertContainer {
	position: fixed;
	width: 100%;
	bottom: 40px;
	left: 0;
	z-index: 3;
}
.bottomAlert {
	width: 1100px;
	background: #212529;
	box-shadow: 0 4px 4px rgba(33, 37, 41, 0.15);
	border-radius: 8px;
	color: #ffffff;
	padding: 16px 24px;
	margin: 0 auto;
	@media (max-width: 1190px) {
		width: calc(100% - 80px);
	}
	@media (max-width: 600px) {
		width: calc(100% - 32px);
		flex-direction: column;
		gap: 12px;
	}
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 24px;
}
.leftText {
	flex: 1 1 auto;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.03em;
}
.rightText {
	display: flex;
	flex-direction: column;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.03em;
	span {
		color: rgba(255, 255, 255, 0.5);
	}
}
.btnContainer {
	@media (max-width: 600px) {
		width: 100%;
	}
	button {
		height: fit-content;
		white-space: nowrap;
		@media (max-width: 600px) {
			width: 100%;
		}
	}
}
