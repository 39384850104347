.reklamnie-nositeli-page {
	height: 100%;
	max-height: calc(100vh - 65px);
	background: #ffffff;
	display: flex;
	flex-direction: column;

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #ced4da;
		padding: 18px 40px;

		h1 {
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 40px;
			line-height: 48px;
			/* identical to box height, or 120% */
			margin: 0;
			padding: 0;
			letter-spacing: -0.05em;
		}
		button {
			height: 36px;
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.04em;
		}
	}
	&__tabs {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		color: #6c757d;
		letter-spacing: -0.05em;
		padding: 0 40px;

		border-bottom: 1px solid #ced4da;

		.tab {
			padding: 12px 16px;

			cursor: pointer;
			transition: all 0.2s ease-in-out;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			span {
				height: 18px;
				border-radius: 4px;
				padding: 3px 4px 3px 4px;
				margin-left: 4px;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: -0.05em;
			}
		}
		.active {
			color: #007bff;
			border-bottom: 2px solid #007bff;
		}
	}
	&__content {
		display: flex;
		flex-direction: row;
		width: 100%;
		flex: 1 1;
		overflow: hidden;
		.col {
			width: calc(50% - 1px);
			min-width: calc(50% - 1px);
		}
		.col_left::-webkit-scrollbar-track {
			//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			background-color: transparent;
			border-radius: 10px;
		}

		.col_left::-webkit-scrollbar {
			width: 5px;
			background-color: transparent;
		}

		.col_left::-webkit-scrollbar-thumb {
			background-color: rgba(33, 37, 41, 0.3);
			border-radius: 10px;
		}
		.col_left::-webkit-scrollbar-thumb:hover {
			background-color: rgba(33, 37, 41, 0.5);
			border-radius: 10px;
		}

		.col_left {
			width: 50%;
			display: flex;
			flex-direction: column;
			// display: flex;
			// flex-direction: column;
			// justify-content: space-between;
			// margin-top: 3px;
			// margin-right: 3px;
			// padding: 24px 24px 24px 24px;
			.list {
				// flex: 1 1;
				// padding: 24px;
				// border-bottom: 1px solid #ced4da;
				// overflow: hidden;
				// position: relative;
				// display: flex;
				// flex-direction: column;
				// align-items: center;
				// overflow-y: auto;
				// overflow-x: hidden;
				// flex: 1 1 auto;
				// margin-right: -24px;
				// width: 100%;
			}
			.pagination {
			}
		}
		.col_right {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	&__rekl-item {
		width: 100%;
		min-height: 112px;
		height: fit-content;
		//overflow: hidden;
		padding: 16px;
		border-radius: 8px;
		&:hover {
			background-color: #f5f5f5;
		}
		.first_row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
			.image_container {
				overflow: hidden;
				border-radius: 50%;
				max-width: 40px;
				min-width: 40px;
				max-height: 40px;
				min-height: 40px;
				display: grid;
				place-items: center;
				svg {
					path {
						fill: #ffffff;
					}
				}
			}
			.data {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				//justify-content: space-around;

				margin: 0 5px 0 16px;
				flex-grow: 1;
				cursor: default;
				span {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: -0.04em;
					color: #6c757d;
				}
			}
		}
		h6 {
			margin: 8px 0 0;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}
	&__rekl-item_selected {
		background-color: #f5f5f5;
	}
	.separator {
		min-height: calc(100vh - 294px);
		border-right: 1px solid #ced4da;
		content: '';
		width: 1px;
		max-width: 1px;
		display: inline-block;
	}

	&__dropdown_container {
		padding: 8px 0px;
		background: #ffffff;
		border: 1px solid #ced4da;
		min-width: 220px;
		max-width: 220px;
		width: 220px;
		box-shadow: 0px 4px 4px rgba(33, 37, 41, 0.15);
		border-radius: 4px;

		div {
			width: 100%;
			padding: 8px 16px;
			color: var(--bs-secondary);
			i {
				margin-right: 8px;
			}
			&:hover {
				background-color: #f5f5f5;
				color: var(--bs-dark);
			}
		}
	}
}
