//Desktop
.internetContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin-bottom: 17px;
	gap: 24px;
	width: 100%;
}
.internetCard {
	width: 100%;
	background-color: #ffffff;
	border-radius: 8px;
	padding: 40px;
}
.internetHeader {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	height: 40px;
	margin-bottom: 24px;
}
.internetColumns {
	display: flex;
	flex-wrap: nowrap;
	gap: 40px;
}

//Mobile
.mobileInternetCard {
	width: 100%;
	background-color: #ffffff;
	border-radius: 8px;
	padding: 16px;
	position: relative;
	display: flex;
	flex-direction: column;
}
.mobileDetailsBtn {
	width: 48px;
	height: 48px;
	display: grid;
	place-items: center;
	position: absolute;
	top: 0;
	right: 0;
	background: #ffffff !important;
	border: none;
	border-radius: 8px;
}

.trashBtn {
	&:hover {
		background-color: #dc3545;
		svg path {
			fill: #ffffff;
		}
	}
}
.yandexPreview {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 12px 16px 12px 16px;
	gap: 2px;
	background: #ffffff;
	border: 1px solid #ced4da;
	border-radius: 8px;
	div {
		display: flex;
		align-items: center;
		img {
			width: 16px;
			height: 16px;
			object-fit: scale-down;
			margin-right: 6px;
		}
		h5 {
			margin-bottom: 0;
			font-family: 'Arial', serif;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			color: #020c7e;
			cursor: pointer;
		}
	}
	span {
		font-family: 'Arial', serif;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: #095f09;
		padding-left: 22px;
		margin-bottom: 2px;
		cursor: pointer;
	}
	p {
		padding-left: 22px;
		margin-bottom: 0;
		font-family: 'Arial', serif;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 17px;
		display: flex;
		align-items: center;
		color: #212529;
	}
}
