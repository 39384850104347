.listItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ced4da;
	padding-bottom: 8px;
	margin-top: 8px;
}

.list {
	list-style: none;
	margin: 0;
	text-align: right;

	li {
		margin-bottom: 4px;
		hyphens: auto;
	}

	li:last-child {
		margin-bottom: 0;
	}
}
