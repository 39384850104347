.tvContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 16px;
}
.tvBudget {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	padding: 24px 40px;
	border-radius: 8px;
	gap: 24px;
	background-color: #ffffff;
	align-items: center;
}
.tvItem {
	padding: 40px;
	background-color: #ffffff;
	border-radius: 8px;
}
.tvHeader {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	//align-items: center;
	height: 40px;
	margin-bottom: 24px;
	h5 {
		margin-bottom: 0;
	}
}
.tvColumns {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
}
.trashBtn {
	&:hover {
		background-color: #dc3545;
		svg path {
			fill: #ffffff;
		}
	}
}
