.notificationInfoBody {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.notificationInfoDate {
	display: flex;
	justify-content: space-between;
	//styleName: Body/Caption;
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.03em;
	text-align: left;
	color: #6c757d;
}
.invisibleModal {
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;
}
.itemList {
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.05em;
}

//mobile view
@media only screen and (max-width: 600px) {
	.notificationInfoBody {
		gap: 1rem;
	}
	.itemList {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.03em;
	}
}
