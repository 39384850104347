.login {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.inputs {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.passwordContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;

	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		color: #6c757d;
		cursor: pointer;

		&:hover {
			color: #007bff;
		}
	}
}
.loginHeader {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;

	h3 {
		font-family: Inter;
		font-size: 28px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.02em;
		margin: 0;
	}

	h6 {
		margin: 0;

		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: #007bff;
	}
}
.loginFooter {
	display: flex;
	gap: 4px;
	justify-content: center;
	p {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #949494;
		margin: 0;
	}
	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #007bff;
		cursor: pointer;
	}
}
.autofillNone {
	&:-webkit-autofill:focus {
		box-shadow: 0 0 0 30px white inset !important;
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-webkit-text-fill-color: #000 !important;
	}

	&:-webkit-autofill {
		box-shadow: 0 0 0 30px white inset !important;
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-webkit-text-fill-color: #000 !important;
	}
}
//mobile view
@media only screen and (max-width: 600px) {
	.login {
		gap: 32px;
	}
}
