.notificationActionRow {
	padding: 16px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.notificationActionHeader {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	padding: 0;
	background: none;

	h5 {
		margin-bottom: 10px;
	}
}

.notificationDelete {
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	padding: 6px 12px;
	position: relative;
	cursor: pointer;
	p {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.04em;
	}

	&:hover {
		color: #dc3545;
	}
}
.infoField {
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: 100%;
	overflow-wrap: break-word;
	span {
		font-family: Inter;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.03em;
		text-align: left;
		color: #6c757d;
	}
	p {
		max-width: 100%;
		margin: 0;
		font-family: Inter;
		font-size: 20px;
		font-weight: 400;
		line-height: 30px;
		letter-spacing: -0.05em;
	}
}

.sender {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.senderButtons {
	border-radius: 5px;
	width: 100%;
}
.clientSender {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.clientSenderTypeSelector {
	display: flex;
	gap: 40px;
}

.choisesItem {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 8px 0;
	border-bottom: 1px solid #ced4da;
	> p {
		margin: 0;
	}
	label {
		letter-spacing: -0.03em;
		cursor: pointer;
	}

	&:first-child {
		padding-top: 0;
	}
	&:last-child {
		border-bottom: none;
	}
}
.choisesItem_disabled {
	p {
		color: #ced4da;
	}

	label {
		color: #ced4da;
		opacity: 1 !important;
	}
}
.noNotify {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	height: 100%;
	justify-content: center;
	> svg {
		fill: #6c757d;
		width: 40px;
		height: 40px;
	}
	> p {
		color: #6c757d;
		font-family: Inter;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.04em;
		text-align: center;
	}

	@media (max-width: 600px) {
		gap: 8px;
		> svg {
			width: 20px;
			height: 20px;
		}
		> p {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.03em;
			text-align: center;
		}
	}
}
.senderButton {
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.04em;
}
//mobile view
@media only screen and (max-width: 600px) {
	.notificationActionRow {
		padding: 12px 16px;
		border-bottom: 1px solid #ced4da;
	}
	.infoField {
		span {
			font-size: 12px;
			line-height: 20px;
			letter-spacing: -0.03em;
		}
		p {
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.03em;
		}
	}
	.senderButton {
		font-size: 16px !important;
		line-height: 24px;
	}
}
