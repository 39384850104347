@import "variables";
.provider-create {
  padding-top: 40px;
  letter-spacing: -0.05rem;
  color: #212529;
  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  &__body {
    max-width: 504px;

  }
  &__desc {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    color: #949494;
     a {
       text-decoration: none ;
     }
    &_mt24 {
      margin-top: 24px;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 500;
    letter-spacing: -0.05em;
  }

  &__input {
    position: relative;
  }

  &__password {
    position: relative;
  }

  &__popover {
    font-size: 24px;
    position: absolute;
    bottom: 11px;
    right: 16px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    z-index: 3;
  }

  &__control {
    margin-bottom: 24px;
  }

  &__submit-button {
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &__button-icon {
    margin-right: 4px;
  }

  &__text {
    text-align: center;
    color: $gray02;
    margin-bottom: 24px;
  }

  &__link {
    text-decoration: none !important;
    cursor: pointer;
    color: $primary01;
  }

  &__message-text {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  &__checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &__checkbox-input {
    margin-top: 0;
  }

  &__checkbox-label {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
    color: #212529;
  }

  &__button-link {
    width: 100%;
    text-decoration: none;
  }
}